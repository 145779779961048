.fa
	font-family: var(--fa-style-family, "Font Awesome 6 Pro")
	font-weight: var(--fa-style, 900)

.fa, .fas, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin, .fad, .fa-duotone, .fab, .fa-brands
	-moz-osx-font-smoothing: grayscale
	-webkit-font-smoothing: antialiased
	display: var(--fa-display, inline-block)
	font-style: normal
	font-variant: normal
	line-height: 1
	text-rendering: auto

// Font Awesome 6 Brands
::root, ::host
	--fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands"

@font-face
	font-family: 'Font Awesome 6 Brands'
	font-style: normal
	font-weight: 400
	font-display: block
	src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype")

.fab,
.fa-brands
	font-family: 'Font Awesome 6 Brands'
	font-weight: 400
// END Font Awesome 6 Brands

// Font Awesome 6 Pro - 300
::root, ::host
	--fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro"

@font-face
	font-family: 'Font Awesome 6 Pro'
	font-style: normal
	font-weight: 300
	font-display: block
	src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype")

.fal,
.fa-light
	font-family: 'Font Awesome 6 Pro'
	font-weight: 300
// END Font Awesome 6 Pro - 300

// Font Awesome 6 Pro - 400
::root, ::host
	--fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro"

@font-face
	font-family: 'Font Awesome 6 Pro'
	font-style: normal
	font-weight: 400
	font-display: block
	src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype")

.far,
.fa-regular
	font-family: 'Font Awesome 6 Pro'
	font-weight: 400
// END Font Awesome 6 Pro - 400

// Font Awesome 6 Pro - 900
::root, ::host
	--fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro"

@font-face
	font-family: 'Font Awesome 6 Pro'
	font-style: normal
	font-weight: 900
	font-display: block
	src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype")

.fas,
.fa-solid
	font-family: 'Font Awesome 6 Pro'
	font-weight: 900
// END Font Awesome 6 Pro - 900

// Font Awesome 6 Pro - 100
::root, ::host
	--fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro"

@font-face
	font-family: 'Font Awesome 6 Pro'
	font-style: normal
	font-weight: 100
	font-display: block
	src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype")

.fat,
.fa-thin
	font-family: 'Font Awesome 6 Pro'
	font-weight: 100
// END Font Awesome 6 Pro - 100
