.modal
	@apply hidden bg-transparent bg-none p-0
	.modal-wrap
		@apply bg-white rounded-2 shadow p-6 xl:px-10 xl:pb-8 xl:pt-12 xl:rounded-4

.modal-head

.modal-body

.modal-about
	@apply max-w-[calc(1088/1920*100rem)]
