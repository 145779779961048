@charset "UTF-8";
/**
 * This injects Tailwind's base styles and any base styles registered-500 by
 * plugins.
 */
@tailwind base {}/**
 * This injects Tailwind's component classes and any component classes
 * registered-500 by plugins.
 */
@tailwind components {}/**
 * This injects Tailwind's utility classes and any utility classes registered-500
 * by plugins.
 */
@tailwind utilities {}/**
 * Use this directive to control where Tailwind injects the hover, focus,
 * responsive, dark mode, and other variants of each class.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants {}.edit-link i:before {
  content: '✏️'; }

@font-face {
  font-family: 'Source Serif Pro';
  src: url("../fonts/SourceSerifPro-BoldIt.eot");
  src: url("../fonts/SourceSerifPro-BoldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSerifPro-BoldIt.woff2") format("woff2"), url("../fonts/SourceSerifPro-BoldIt.woff") format("woff"), url("../fonts/SourceSerifPro-BoldIt.ttf") format("truetype"), url("../fonts/SourceSerifPro-BoldIt.svg#SourceSerifPro-BoldIt") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Source Serif Pro';
  src: url("../fonts/SourceSerifPro-Bold.eot");
  src: url("../fonts/SourceSerifPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSerifPro-Bold.woff2") format("woff2"), url("../fonts/SourceSerifPro-Bold.woff") format("woff"), url("../fonts/SourceSerifPro-Bold.ttf") format("truetype"), url("../fonts/SourceSerifPro-Bold.svg#SourceSerifPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Source Serif Pro';
  src: url("../fonts/SourceSerifPro-Light.eot");
  src: url("../fonts/SourceSerifPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSerifPro-Light.woff2") format("woff2"), url("../fonts/SourceSerifPro-Light.woff") format("woff"), url("../fonts/SourceSerifPro-Light.ttf") format("truetype"), url("../fonts/SourceSerifPro-Light.svg#SourceSerifPro-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Source Serif Pro';
  src: url("../fonts/SourceSerifPro-It.eot");
  src: url("../fonts/SourceSerifPro-It.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSerifPro-It.woff2") format("woff2"), url("../fonts/SourceSerifPro-It.woff") format("woff"), url("../fonts/SourceSerifPro-It.ttf") format("truetype"), url("../fonts/SourceSerifPro-It.svg#SourceSerifPro-It") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Source Serif Pro';
  src: url("../fonts/SourceSerifPro-Black.eot");
  src: url("../fonts/SourceSerifPro-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSerifPro-Black.woff2") format("woff2"), url("../fonts/SourceSerifPro-Black.woff") format("woff"), url("../fonts/SourceSerifPro-Black.ttf") format("truetype"), url("../fonts/SourceSerifPro-Black.svg#SourceSerifPro-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Source Serif Pro';
  src: url("../fonts/SourceSerifPro-ExtraLightIt.eot");
  src: url("../fonts/SourceSerifPro-ExtraLightIt.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSerifPro-ExtraLightIt.woff2") format("woff2"), url("../fonts/SourceSerifPro-ExtraLightIt.woff") format("woff"), url("../fonts/SourceSerifPro-ExtraLightIt.ttf") format("truetype"), url("../fonts/SourceSerifPro-ExtraLightIt.svg#SourceSerifPro-ExtraLightIt") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Source Serif Pro';
  src: url("../fonts/SourceSerifPro-ExtraLight.eot");
  src: url("../fonts/SourceSerifPro-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSerifPro-ExtraLight.woff2") format("woff2"), url("../fonts/SourceSerifPro-ExtraLight.woff") format("woff"), url("../fonts/SourceSerifPro-ExtraLight.ttf") format("truetype"), url("../fonts/SourceSerifPro-ExtraLight.svg#SourceSerifPro-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Source Serif Pro';
  src: url("../fonts/SourceSerifPro-BlackIt.eot");
  src: url("../fonts/SourceSerifPro-BlackIt.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSerifPro-BlackIt.woff2") format("woff2"), url("../fonts/SourceSerifPro-BlackIt.woff") format("woff"), url("../fonts/SourceSerifPro-BlackIt.ttf") format("truetype"), url("../fonts/SourceSerifPro-BlackIt.svg#SourceSerifPro-BlackIt") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Source Serif Pro';
  src: url("../fonts/SourceSerifPro-LightIt.eot");
  src: url("../fonts/SourceSerifPro-LightIt.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSerifPro-LightIt.woff2") format("woff2"), url("../fonts/SourceSerifPro-LightIt.woff") format("woff"), url("../fonts/SourceSerifPro-LightIt.ttf") format("truetype"), url("../fonts/SourceSerifPro-LightIt.svg#SourceSerifPro-LightIt") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Source Serif Pro';
  src: url("../fonts/SourceSerifPro-Semibold.eot");
  src: url("../fonts/SourceSerifPro-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSerifPro-Semibold.woff2") format("woff2"), url("../fonts/SourceSerifPro-Semibold.woff") format("woff"), url("../fonts/SourceSerifPro-Semibold.ttf") format("truetype"), url("../fonts/SourceSerifPro-Semibold.svg#SourceSerifPro-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Source Serif Pro';
  src: url("../fonts/SourceSerifPro-Regular.eot");
  src: url("../fonts/SourceSerifPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSerifPro-Regular.woff2") format("woff2"), url("../fonts/SourceSerifPro-Regular.woff") format("woff"), url("../fonts/SourceSerifPro-Regular.ttf") format("truetype"), url("../fonts/SourceSerifPro-Regular.svg#SourceSerifPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Source Serif Pro';
  src: url("../fonts/SourceSerifPro-SemiboldIt.eot");
  src: url("../fonts/SourceSerifPro-SemiboldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/SourceSerifPro-SemiboldIt.woff2") format("woff2"), url("../fonts/SourceSerifPro-SemiboldIt.woff") format("woff"), url("../fonts/SourceSerifPro-SemiboldIt.ttf") format("truetype"), url("../fonts/SourceSerifPro-SemiboldIt.svg#SourceSerifPro-SemiboldIt") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900); }

.fa, .fas, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin, .fad, .fa-duotone, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }

::root, ::host {
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands"; }

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype"); }

.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

::root, ::host {
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype"); }

.fal,
.fa-light {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300; }

::root, ::host {
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype"); }

.far,
.fa-regular {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

::root, ::host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype"); }

.fas,
.fa-solid {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900; }

::root, ::host {
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype"); }

.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 100; }

.heading-1 {
  @apply text-5xl leading-1.33 xl:text-6xl {} }

.heading-2 {
  @apply text-4xl leading-1.33 xl:text-5xl {} }

.heading-3 {
  @apply text-3xl leading-1.33 xl:text-4xl {} }

.heading-4 {
  @apply text-2xl leading-1.33 xl:text-3xl {} }

.heading-5 {
  @apply text-xl leading-1.33 xl:text-2xl {} }

.body-1 {
  @apply text-xl leading-1.4 {} }

.body-2 {
  @apply text-[14px] leading-normal xl:text-base {} }

.body-4 {
  @apply text-[14px] leading-1.4 xl:text-sm {} }

.body-5 {
  @apply text-[14px] leading-1.33 xl:text-xs {} }

.global-title {
  @apply font-primary {} }

.site-title {
  @apply font-secondary font-semibold {} }

.site-semi-48 {
  @apply site-title text-5xl leading-[calc(52/48)] font-semibold xl:text-6xl {} }

.site-bold-36 {
  @apply site-title text-3xl leading-1.33 font-bold xl:text-4xl {} }

.site-semi-36 {
  @apply site-title text-3xl leading-1.33 font-semibold xl:text-4xl {} }

.site-bold-24 {
  @apply site-title text-[20px] leading-1.33 font-bold xl:text-2xl {} }

.site-semi-24 {
  @apply site-title text-[20px] leading-1.33 font-semibold xl:text-2xl {} }

.site-regular-20 {
  @apply site-title text-[18px] leading-1.4 font-normal xl:text-xl {} }

.site-regular-16 {
  @apply site-title text-base leading-normal font-normal {} }

.title-gradient {
  -webkit-text-fill-color: transparent;
  @apply bg-clip-text {} }

.site-desc {
  @apply site-regular-20 {} }

.button {
  @apply flex flex-wrap gap-5 {} }

.btn {
  @apply flex-center h-11 px-4 gap-2 rounded-full font-bold text-secondary-600 text-sm whitespace-nowrap hover:text-primary-500 {} }
  .btn i, .btn em {
    @apply text-xl {} }

.btn-solid {
  @apply relative bg-primary-500 hover:bg-secondary-600 hover:text-white {} }
  .btn-solid i, .btn-solid em, .btn-solid span {
    @apply relative z-2 {} }

.btn-lined {
  @apply relative bg-transparent font-normal uppercase border border-neutral-500 text-neutral-500 hover:bg-secondary-600 hover:border-secondary-600 hover:text-white {} }
  .btn-lined.btn-white {
    @apply border-white text-white {} }

@screen xl {
  .btn-lined {
    @apply px-8 gap-3 hover:gap-5 {} } }

.alert {
  @apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm {} }
  .alert button {
    @apply hidden {} }
  .alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }

.backdrop {
  @apply fixed z-500 w-full h-full top-0 left-0 bg-black/75 pointer-events-none opacity-0 transition-all {} }
  .backdrop.open {
    @apply opacity-100 pointer-events-auto {} }

.nav-fixed {
  @apply fixed z-50 bottom-25 md:bottom-5 right-3 scale-75 xl:bottom-10 xl:right-10 xl:scale-100 {} }
  .nav-fixed ul {
    @apply flex flex-col justify-center items-center gap-4 {} }
    .nav-fixed ul > li > a {
      @apply relative z-1 w-15 h-15 rounded-full flex-center bg-yellow text-2xl text-secondary-600 transition hover:bg-main {} }
    .nav-fixed ul > li > .nav-popup {
      @apply relative z-1 {} }
      .nav-fixed ul > li > .nav-popup::before {
        content: '';
        @apply absolute-center w-full h-full -z-1 bg-primary-400 opacity-25 rounded-full animate-circle-zoom {} }
  .nav-fixed .back-to-top {
    @apply opacity-0 pointer-events-none relative w-15 h-15 {} }
    .nav-fixed .back-to-top.active {
      @apply opacity-100 pointer-events-auto {} }
    .nav-fixed .back-to-top .next-section {
      @apply bg-white static block rotate-180 {} }
      .nav-fixed .back-to-top .next-section span {
        @apply after:bg-neutral-900 before:bg-neutral-900 {} }
      @media (max-width: 575.89px) {
        .nav-fixed .back-to-top .next-section .arrow::before, .nav-fixed .back-to-top .next-section .arrow:after {
          @apply animate-none {} } }
  .nav-fixed .ActionMenu {
    left: auto !important;
    right: 0 !important; }

.social-list {
  @apply flex items-center gap-3 {} }
  .social-list a {
    @apply flex-center w-12 h-12 rounded-full bg-secondary-600 text-neutral-white text-2xl hover:bg-primary-600 {} }

.global-desc {
  @apply prose max-w-none {}  @apply body-4 {}  @apply text-neutral-500 {} }
  .global-desc h3 {
    @apply title-gradient site-semi-24 {}    @apply font-bold bg-title font-secondary {} }
  .global-desc.center {
    @apply max-w-[calc(864/1920*100rem)] mx-auto {} }

.full-content {
  @apply prose max-w-none {}  @apply body-4 {} }
  .full-content h2, .full-content h3, .full-content h4, .full-content h5 {
    @apply font-secondary text-secondary-500 mt-0 {} }
  .full-content h2 {
    @apply site-bold-36 {} }
  .full-content h3 {
    @apply site-bold-24 {} }
  .full-content p img {
    @apply m-0 {} }
  .full-content .row {
    @apply mb-0 {} }
    .full-content .row > [class*="col-"] {
      @apply mb-8 {} }
  .full-content > * {
    @apply first:mt-0 {} }

.next-section {
  @apply hidden w-15 h-15 ring-4 ring-white shadow bg-primary-500 rounded-full cursor-pointer relative {} }
  .next-section .arrow {
    @apply absolute-center z-1 {} }
    .next-section .arrow::before, .next-section .arrow::after {
      content: '';
      @apply absolute w-[13px] h-[1px] transition-all bg-neutral-100 {}      @apply bg-white animate-next-section {} }
    .next-section .arrow::before {
      @apply -right-px rotate-[35deg] {} }
    .next-section .arrow::after {
      @apply -left-px -rotate-[35deg] {} }
    .next-section .arrow:nth-child(1) {
      @apply top-[calc(50%-8px)] {} }
      .next-section .arrow:nth-child(1)::before, .next-section .arrow:nth-child(1)::after {
        @apply h-[1px] {} }
    .next-section .arrow:nth-child(2)::before, .next-section .arrow:nth-child(2)::after {
      @apply h-[1.5px] animation-delay-100 {} }
    .next-section .arrow:nth-child(3) {
      @apply top-[calc(50%+8px)] {} }
      .next-section .arrow:nth-child(3)::before, .next-section .arrow:nth-child(3)::after {
        @apply h-[2px] animation-delay-200 {} }

.dropdown {
  @apply relative {} }
  .dropdown .dropdown-toggle {
    @apply flex items-center gap-1 {} }
  .dropdown .dropdown-menu {
    @apply absolute top-full right-0 w-max bg-primary-500 px-4 py-2 rounded-1 shadow hidden {} }
    .dropdown .dropdown-menu a {
      @apply inline-block py-2 text-sm text-secondary-600 font-normal uppercase leading-1.4 hover-underline {} }

input[type='text'],
input[type='email'],
input[type='password'],
input[type='repassword'],
[multiple],
textarea,
select {
  @apply relative w-full bg-white border border-[#e5e5e5] h-12 px-5 text-base text-secondary-600 font-normal {}  @apply focus:ring-0 {}  @apply placeholder:text-neutral-400 {}  @apply disabled:bg-neutral-50 disabled:border-neutral-50 disabled:text-neutral-700 {}  @apply read-only:bg-neutral-50 read-only:border-neutral-50 read-only:text-neutral-700 {} }

textarea {
  @apply h-25 py-4 {} }

input[type='file'] {
  @apply hidden {} }
  input[type='file'] ~ label {
    @apply relative w-max bg-neutral-50 border-none h-10 px-6 py-3 text-base text-primary-700 font-normal flex-center gap-3 cursor-pointer m-0 transition-all duration-200 ease-linear hover:bg-neutral-400 {} }

input[type='checkbox'] {
  @apply hidden {} }
  input[type='checkbox'] ~ label {
    @apply relative pl-5 {} }
    input[type='checkbox'] ~ label::before {
      @apply font-awesome content-['\f0c8'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-900 font-normal {} }
  input[type='checkbox']:checked ~ label::before {
    @apply content-['\f14a'] {} }

input[type='radio'] {
  @apply hidden {} }
  input[type='radio'] ~ label {
    @apply relative pl-5 {} }
    input[type='radio'] ~ label::before {
      @apply font-awesome content-['\f111'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-900 font-normal {} }
  input[type='radio']:checked ~ label::before {
    @apply content-['\f058'] {} }

.form-group {
  @apply relative {} }
  .form-group > label {
    @apply relative block text-base font-normal leading-normal text-neutral-900 {} }
  .form-group [class*='fa-ex'] {
    @apply text-[12px] text-red-700 italic block mt-0.5 font-primary font-normal {} }

.frm-btnwrap label.label, .frm-btnwrap .frm-btn-reset {
  @apply hidden {} }

.frm-btnwrap .frm-btn {
  @apply relative flex w-max {} }
  .frm-btnwrap .frm-btn::after {
    @apply font-awesome content-['\f178'] font-light text-neutral-500 absolute top-1/2 -translate-y-1/2 right-8 leading-none pointer-events-none tsn {} }
  .frm-btnwrap .frm-btn input[type='submit'] {
    @apply w-max h-12 pl-8 pr-15 py-1 text-base font-normal bg-transparent border border-neutral-500 rounded-full uppercase text-neutral-500 cursor-pointer tsn {} }
  .frm-btnwrap .frm-btn:hover {
    @apply after:text-primary-500 {} }
    .frm-btnwrap .frm-btn:hover input[type='submit'] {
      @apply text-primary-500 bg-secondary-600 border-secondary-600 {} }

.frm-captcha {
  @apply mt-5 flex flex-col gap-4 {} }
  .frm-captcha .frm-captcha-input label {
    @apply hidden {} }
  .frm-captcha .frm-captcha-input input {
    @apply bg-transparent border-[#E5E5E5] text-base font-normal text-neutral-500 px-3 py-1 min-w-[calc(160/1920*100rem)] {} }
  .frm-captcha .rcRefreshImage {
    @apply text-0 hover:after:text-secondary-600 {} }
    .frm-captcha .rcRefreshImage::after {
      @apply font-awesome content-['\f0e2'] font-light text-3xl text-neutral-900 {} }
  .frm-captcha .RadCaptcha {
    width: 100% !important;
    @apply relative flex flex-col-reverse gap-1 {} }
    .frm-captcha .RadCaptcha > span {
      @apply text-xs font-normal text-red-600 {} }
    .frm-captcha .RadCaptcha div {
      @apply flex flex-row-reverse justify-end items-center gap-4 {} }

@screen md {
  .frm-captcha {
    @apply flex-row {} } }

@screen xl {
  .frm-captcha .RadCaptcha > span {
    @apply absolute top-full left-12 mt-1 {} } }

.RadUpload {
  @apply max-w-full {} }
  .RadUpload input[type="file"] {
    @apply block {} }
  .RadUpload .ruInputs .ruRemove {
    @apply text-0 w-auto {} }
  .RadUpload .ruInputs li:not(:last-child) {
    @apply flex items-center {} }
  .RadUpload .ruInputs li:last-child {
    @apply mb-0 {} }
    .RadUpload .ruInputs li:last-child .ruFileWrap {
      @apply block w-full h-25 rounded-2 border border-dashed border-neutral-400 bg-white p-3 flex-center {} }
      .RadUpload .ruInputs li:last-child .ruFileWrap input {
        @apply w-full h-full bg-white border-none text-main text-center {} }

.apply-frm {
  @apply p-5 {} }
  .apply-frm .form-group {
    @apply mb-8 last:mb-0 {} }
    .apply-frm .form-group label {
      @apply text-base w-full text-main font-normal mb-1 {} }
      .apply-frm .form-group label span {
        @apply text-secondary-700 text-sm {} }
    .apply-frm .form-group input:not([type="button"]), .apply-frm .form-group textarea {
      @apply border-neutral-400 {} }
  .apply-frm .frm-btn {
    @apply w-full flex justify-end {} }

@screen lg {
  .apply-frm {
    @apply py-8 px-10 {} }
    .apply-frm .form-group {
      @apply flex gap-8 {} }
      .apply-frm .form-group label {
        @apply w-[30%] mb-0 text-base {} }
      .apply-frm .form-group input:not([type="button"]) {
        @apply h-10 text-base {} }
      .apply-frm .form-group textarea {
        @apply h-25 text-base {} } }

.modal {
  @apply hidden bg-transparent bg-none p-0 {} }
  .modal .modal-wrap {
    @apply bg-white rounded-2 shadow p-6 xl:px-10 xl:pb-8 xl:pt-12 xl:rounded-4 {} }

.modal-about {
  @apply max-w-[calc(1088/1920*100rem)] {} }

.pagination {
  @apply flex-center flex-wrap gap-3 mt-8 {} }
  .pagination span, .pagination a {
    @apply flex-center text-base text-primary-500 text-center w-10 h-10 rounded-2 bg-secondary-600 tsn hover:bg-primary-500 hover:text-secondary-600 {} }
  .pagination li.active span, .pagination li.active a {
    @apply bg-primary-500 text-secondary-600 {} }

@screen xl {
  .pagination {
    @apply gap-5 mt-10 {} }
    .pagination span, .pagination a {
      @apply w-12 h-12 text-xl {} } }

.custom-scroll-dark::-webkit-scrollbar {
  width: 14px;
  height: 14px; }

.custom-scroll-dark::-webkit-scrollbar-button {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-track {
  background-color: #646464 !important; }

.custom-scroll-dark::-webkit-scrollbar-track-piece {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #242424 !important;
  border: 2px solid #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:start:decrement {
  background: linear-gradient(130deg, #696969 40%, rgba(255, 0, 0, 0) 41%), linear-gradient(230deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(0deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:end:increment {
  background: linear-gradient(310deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(50deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(180deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:end:increment {
  background: linear-gradient(210deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(330deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(90deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:start:decrement {
  background: linear-gradient(30deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(150deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(270deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.swiper-pagination .swiper-pagination-bullet {
  @apply opacity-100 bg-neutral-400 {} }
  .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-primary-700 opacity-100 {} }

.swiper-pagination.is-white .swiper-pagination-bullet {
  @apply bg-white/50 {} }
  .swiper-pagination.is-white .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-white/100 {} }

.swiper-button > * {
  @apply text-2xl text-secondary-500 hover:text-primary-500 {} }
  .swiper-button > *.swiper-button-disabled {
    @apply opacity-40 {} }
  .swiper-button > *.swiper-button-lock {
    @apply opacity-0 pointer-events-none {} }

.swiper-button.is-white > * {
  @apply text-white hover:text-neutral-200 {} }

.swiper-button.is-absolute > * {
  @apply absolute top-1/2 -translate-y-1/2 z-1 {} }

.swiper-button.is-absolute .button-prev {
  @apply left-1 {} }

.swiper-button.is-absolute .button-next {
  @apply right-1 {} }

.swiper-button.is-absolute.is-top-20 > * {
  @apply top-[20%] {} }

.swiper-button.is-absolute.is-top-30 > * {
  @apply top-[30%] {} }

.swiper-button.is-absolute.is-top-40 > * {
  @apply top-[40%] {} }

.swiper-relative .swiper-pagination {
  @apply xl:hidden {} }

.swiper-relative .swiper-button {
  @apply hidden {} }
  .swiper-relative .swiper-button.is-absolute .button-prev {
    @apply -left-12 {} }
  .swiper-relative .swiper-button.is-absolute .button-next {
    @apply -right-12 {} }

@screen xl {
  .swiper-relative .swiper-button {
    @apply block {} } }

.swiper-relative .swiper-scrollbar {
  @apply h-[2px] bg-[#D9D9D9] {} }
  .swiper-relative .swiper-scrollbar .swiper-scrollbar-drag {
    @apply bg-secondary-500 {} }

@layer base {
  html, body {
    font-size: 16px; }
  @screen md {
    html, body {
      font-size: 16px; } }
  @screen xl {
    html, body {
      font-size: 1.25vw; } }
  @screen 2xl {
    html, body {
      font-size: 1vw; } }
  body {
    @apply font-primary overflow-y-overlay text-main {} }
  h1 {
    @apply text-4xl font-bold {} }
  h2 {
    @apply text-3xl font-bold {} }
  h3 {
    @apply text-2xl font-bold {} }
  h4 {
    @apply text-xl font-bold {} }
  h5 {
    @apply text-lg font-bold {} }
  h6 {
    @apply text-base font-bold {} }
  a {
    @apply transition-all ease-linear duration-200 hover:text-current {} }
  img {
    @apply max-w-full inline {} }
  main, div {
    @apply text-base {} } }

@layer components {
  @screen xs {
    .container {
      @apply max-w-full {} } }
  @screen sm {
    .container {
      @apply max-w-[540px] {} } }
  @screen md {
    .container {
      @apply max-w-[720px] {} } }
  @screen lg {
    .container {
      @apply max-w-[980px] {} } }
  @screen xl {
    .container {
      @apply max-w-[calc(1440/1920*100rem)] {} } }
  @screen 2xl {
    .container {
      @apply max-w-[calc(1600/1920*100rem)] {} } }
  @media (min-width: 1600px) {
    .container {
      @apply max-w-[calc(1344/1920*100rem)] {} } }
  .equal-height .row > [class*=col] > * {
    @apply h-full {} }
  .equal-height .swiper-slide {
    @apply h-auto {} }
    .equal-height .swiper-slide > * {
      @apply h-full {} }
  .lozad-bg {
    @apply bg-center bg-no-repeat bg-cover {} } }

@layer utilities {
  .container-fuild {
    @apply px-4 {} }
  .section {
    @apply py-15 xl:py-20 {} }
  .section-b {
    @apply pb-10 xl:pb-15 {} }
  .section-small {
    @apply py-8 xl:py-10 {} }
  .section-b-small {
    @apply b-8 xl:b-10 {} }
  .section-large {
    @apply py-15 xl:py-20 {} }
  .section-b-large {
    @apply pb-15 xl:pb-20 {} }
  .row {
    @apply flex flex-wrap -mx-4 {} }
    .row > * {
      @apply px-4 {} }
  .no-gutters {
    @apply mx-0 {} }
    .no-gutters > * {
      @apply px-0 {} }
  .swiper-relative {
    @apply relative {} }
  .writing-mode-tb-rl {
    writing-mode: vertical-rl;
    text-orientation: mixed; }
  .media-scale {
    @apply relative block h-0 overflow-hidden {} }
    .media-scale img, .media-scale iframe, .media-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .img-scale {
    @apply relative block h-0 overflow-hidden {} }
    .img-scale img {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .iframe-scale {
    @apply relative block h-0 overflow-hidden {} }
    .iframe-scale iframe {
      @apply absolute min-w-full w-0 h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .video-scale {
    @apply relative block h-0 overflow-hidden {} }
    .video-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .bg-bright-grey {
    @apply backdrop-blur-25 {} }
  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 {} }
  .filter-white {
    @apply brightness-0 invert {} }
  .img-zoom-in:hover img {
    @apply scale-100 {} }
  .hover-underline {
    @apply relative {}    background: linear-gradient(0deg, theme("colors.current"), theme("colors.current")) no-repeat right bottom/0 theme("spacing[px]");
    transition: background-size 350ms; }
    .hover-underline:hover {
      background-size: 100% theme("spacing[px]");
      background-position-x: left; }
  .hover-underline-active {
    background-size: 100% theme("spacing[px]");
    background-position-x: left; }
  .desktop-only {
    @apply hidden lg:block {} }
  .mobile-only {
    @apply block lg:hidden {} }
  .tsn {
    @apply transition-all duration-200 ease-in-out {} }
  .img-cover {
    @apply relative overflow-hidden {} }
    .img-cover img {
      @apply w-full h-full object-cover tsn {} }
  .img-contain {
    @apply relative overflow-hidden {} }
    .img-contain img {
      @apply w-full h-full object-contain tsn {} }
  .img-zoom:hover img {
    @apply scale-110 tsn {} }
  .text-xs {
    @apply text-[14px] xl:text-[12px] {} } }

.about-count-item {
  @apply py-5 relative {} }
  .about-count-item .count-number {
    @apply heading-4 {}    @apply text-primary-500 {} }
    .about-count-item .count-number .count-item {
      @apply pr-2 font-bold {} }
    .about-count-item .count-number .sub-number {
      @apply heading-5 font-normal {} }
  .about-count-item .title {
    @apply body-4 {}    @apply mt-3 font-bold text-neutral-500 {} }

@screen sm {
  .about-count-item {
    @apply py-7 px-8 {} } }

.about-count-list {
  @apply grid grid-cols-2 gap-x-5 overflow-hidden mx-auto xl:gap-x-8 {} }
  .about-count-list .about-count-item::after {
    content: '';
    @apply absolute top-1/2 -translate-y-1/2 right-0 w-px h-full bg-neutral-100 pointer-events-none {} }
  .about-count-list .about-count-item:nth-child(2n) {
    @apply after:hidden {} }
  .about-count-list .about-count-item:nth-child(n+3) {
    @apply mt-10 {} }
    .about-count-list .about-count-item:nth-child(n+3)::before {
      content: '';
      @apply absolute left-1/2 -translate-x-1/2 -top-5 w-[calc(100%+40px)] h-px bg-neutral-100 pointer-events-none {} }

.about-1-section {
  @apply relative overflow-hidden z-1 bg-primary-50 {} }

@screen xl {
  .about-1-section::after {
    content: '';
    @apply absolute top-[40%] -translate-y-1/2 right-0 w-[calc(294/1920*100rem)] h-[calc(353/1920*100rem)] bg-[url(../img/bird-right.png)] bg-contain bg-center -z-1 {} } }

.about-2-item .title {
  @apply site-semi-24 {}  @apply text-primary-500 {} }

.about-2-item .global-desc {
  @apply body-2 {}  @apply mt-7 text-white font-normal {} }

.about-2-section {
  @apply pt-15 pb-20 md:pb-[calc(200/1920*100rem)] {} }
  .about-2-section .swiper-relative {
    @apply max-w-[calc(528/1920*100rem)] bg-secondary-700/60 backdrop-blur-[12px] px-7 py-8 mt-10 xl:px-8 xl:py-15 {} }
  .about-2-section .swiper-button {
    @apply flex items-center gap-8 {} }
    .about-2-section .swiper-button > * {
      @apply text-white text-2xl {} }

.about-3-item .image {
  @apply aspect-[16/9] {} }

.about-3-item .title {
  @apply site-bold-24 {}  @apply text-secondary-500 mb-5 {} }

.about-3-item .button {
  @apply mt-5 {} }

.about-3-section {
  @apply relative z-1 overflow-hidden {} }
  .about-3-section .swiper {
    @apply overflow-visible pb-10 xl:pb-20 {} }
  .about-3-section .swiper-scrollbar {
    @apply hidden xl:block {} }
  .about-3-section .swiper-pagination {
    @apply xl:hidden {} }

@screen xl {
  .about-3-section {
    @apply mb-20 {} }
    .about-3-section::after {
      content: '';
      @apply absolute bottom-0 -right-[calc(198/1920*100rem)] w-[calc(396/1920*100rem)] h-[calc(602/1920*100rem)] bg-[url(../img/gastronomy.png)] bg-contain bg-center -z-1 opacity-30 {} } }

.about-4-item {
  @apply flex flex-col justify-between items-center bg-white rounded-4 p-6 gap-6 xl:py-8 {} }
  .about-4-item .top {
    @apply flex flex-col justify-center items-center gap-6 {} }
  .about-4-item .icon {
    @apply w-16 h-16 mx-auto flex-center {} }
  .about-4-item .title {
    @apply body-4 {}    @apply text-neutral-500 text-center {} }
  .about-4-item .view-all {
    @apply body-5 {}    @apply text-center font-bold text-secondary-300 uppercase hover:text-secondary-600 {} }

.about-4-section .about-4-swiper {
  @apply pb-10 xl:pb-0 {} }

@screen xl {
  .about-4-section {
    @apply mb-20 {} } }

.about-5-section {
  @apply min-h-[calc(640/1920*100rem)] relative z-1 {} }
  @media (max-width: 767.89px) {
    .about-5-section {
      background-position: 0 0 !important; } }

@screen md {
  .about-5-section::before {
    content: '';
    @apply absolute top-0 right-1/2 bg-gradient-to-r from-white to-white/0 w-1/2 h-full -z-1 pointer-events-none {} }
  .about-5-section .image {
    @apply hidden {} } }

@screen xl {
  .about-5-section {
    @apply mb-20 {} } }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@screen sm {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@screen md {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@screen lg {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@screen xl {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@screen 2xl {
  .col-2xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-2xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-2xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-2xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-2xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-2xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-2xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-2xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-2xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-2xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-2xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-2xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-2xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-2xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

.banner-child {
  @apply relative {} }
  .banner-child .image {
    @apply aspect-square relative {} }
    .banner-child .image::before {
      content: '';
      @apply absolute-center bg-black/20 w-full h-full pointer-events-none {} }
    .banner-child .image img {
      @apply w-full h-full object-cover {} }
  .banner-child .banner-title {
    @apply site-semi-48 text-white {} }
  .banner-child .banner-brief {
    @apply mt-3 heading-4 text-white font-light {} }
  .banner-child .caption {
    @apply absolute bottom-0 left-0 w-full py-10 z-1 {} }

@screen md {
  .banner-child .image {
    @apply aspect-[16/5] {} }
  .banner-child .caption {
    @apply py-10 {} } }

@screen xl {
  .banner-child .caption {
    @apply pt-10 pb-25 {} } }

.news-detail-page .banner-child {
  @apply hidden {} }

.news-detail-page .promotion-banner .banner-child {
  @apply block {} }

.home-banner {
  @apply relative z-2 {} }
  .home-banner .image {
    @apply aspect-square {} }
    .home-banner .image img {
      @apply w-full h-full object-cover {} }
  .home-banner .banner-title {
    @apply site-semi-48 text-white {} }
  .home-banner .banner-brief {
    @apply mt-3 heading-4 text-white font-light {} }
  .home-banner .caption {
    @apply absolute bottom-0 left-0 w-full py-10 {} }
  .home-banner .swiper-pagination {
    @apply flex items-center static translate-x-0 translate-y-0 h-full {} }
    .home-banner .swiper-pagination .swiper-pagination-bullet {
      @apply w-5 h-full bg-white/50 opacity-100 rounded-none mx-1 {} }
      .home-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        @apply bg-white {} }
  .home-banner .banner-pagination {
    @apply flex absolute left-1/2 -translate-x-1/2 w-full bottom-3 z-1 h-1 {} }

@screen md {
  .home-banner .image {
    @apply aspect-[16/7] {} }
  .home-banner .caption {
    @apply py-10 {} }
  .home-banner .swiper-pagination .swiper-pagination-bullet {
    @apply ml-0 mr-2 {} } }

@screen xl {
  .home-banner .caption {
    @apply pt-20 pb-30 mb-6 {} }
  .home-banner .banner-pagination {
    @apply bottom-25 {} }
  .home-banner .next-section {
    @apply flex-center absolute left-0 -bottom-[calc(130/1920*100rem)] {} } }

.global-breadcrumb {
  @apply bg-gray-100 {} }
  .global-breadcrumb .breadcrumb {
    @apply flex flex-wrap items-center gap-4 py-3 {} }
    .global-breadcrumb .breadcrumb a {
      @apply body-5 {}      @apply inline-block text-neutral-400 hover:text-primary-500 {} }
    .global-breadcrumb .breadcrumb li {
      @apply pr-4 border-r border-r-neutral-400 leading-none last:pr-0 last:border-none {} }

.cmsadminpanel .ddviewmode {
  @apply h-[24px] text-[12px] py-1 px-2 pr-5 leading-none {} }

.suggestsearch {
  display: none !important; }

.searchresults .search-page {
  @apply py-15 xl:py-20 {} }

.searchresults .page-header h1 {
  @apply uppercase {}  @apply relative w-max max-w-full pb-2 before:absolute before:bottom-0 before:left-0 before:w-full before:h-px {} }

.searchresults .searchresultsummary {
  @apply text-[11px] xl:text-[13px] mb-3 {} }

.searchresults .searchcontrols .form-inline {
  @apply relative {} }

.searchresults .searchcontrols .form-group {
  @apply hidden first:block last:block last:absolute last:w-10 last:h-full last:top-0 last:right-0 last:z-1 last:text-[0] last:mb-0 last:flex-center {}  @apply last:before:absolute last:before:w-full last:before:h-full last:before:font-awesome last:before:content-['\f002'] last:before:text-blue-500 last:before:text-2xl last:before:flex-center last:before:pointer-events-none {} }
  .searchresults .searchcontrols .form-group:first-child input {
    @apply pr-15 {} }
  .searchresults .searchcontrols .form-group:last-child input {
    @apply z-2 w-10 h-full relative top-0 right-0 opacity-0 {} }

.searchresults .searchresults > .modulepager {
  @apply hidden {} }
  .searchresults .searchresults > .modulepager:last-child {
    @apply block {} }

.searchresults .searchresultlist {
  @apply px-1 xl:px-5 {} }

.searchresults .searchresult {
  @apply mt-10 {} }
  .searchresults .searchresult h3 a {
    @apply text-xl hover:underline hover:text-white {} }
  .searchresults .searchresult .searchresultdesc {
    @apply mt-1 text-base {} }
  .searchresults .searchresult hr {
    @apply hidden {} }

.login-box {
  @apply py-25 px-10 xl:py-25 {} }
  .login-box .card {
    @apply bg-neutral-50 mx-auto p-5 {} }

@screen xl {
  .login-box .card {
    @apply max-w-[calc(750/1920*100rem)] px-[calc(95/1920*100rem)] pt-7 pb-15 {} } }
  .login-box .input-group {
    @apply mb-5 {} }
    .login-box .input-group input {
      @apply border-white bg-white w-full rounded-1 h-10 text-neutral-900 text-base px-5 placeholder:text-neutral-700 {} }
  .login-box .input-group-append {
    @apply hidden {} }
  .login-box .btn.btn-primary.btn-block, .login-box .btn.btn-default.createuserbutton {
    @apply cursor-pointer bg-black border-black transition-all h-10 text-center text-white font-normal uppercase text-sm px-10 rounded-full xl:min-w-[calc(183/1920*100rem)] xl:hover:bg-primary-700 xl:hover:border-primary-700 {} }
  .login-box .social-login {
    @apply hidden {} }
  .login-box .row.items-center {
    @apply justify-between {} }
  .login-box a {
    @apply text-base xl:text-sm {} }

.wrap-register > div > div {
  @apply shadow rounded-5 overflow-hidden bg-white mx-auto p-5 {} }

@screen xl {
  .wrap-register > div > div {
    @apply max-w-[calc(600/1920*100rem)] p-7 {} } }

.wrap-register .settingrow .RadInput .riTextBox {
  @apply w-full bg-neutral-400 border border-solid border-neutral-50 rounded-1 h-10 px-5 text-base text-neutral-900 leading-normal xl:text-sm {} }

.login-logo, .pagetitle {
  @apply text-2xl font-light text-secondary-700 uppercase leading-tight text-center mb-8 {} }

.login-box-msg {
  @apply text-base leading-[1.38] text-secondary-700 font-normal mb-5 {} }

.icheck-primary {
  @apply form-group text-base space-x-3 {} }

.login-download-section .login-box {
  @apply p-0 xl:p-0 {} }
  .login-download-section .login-box .btn-download-submit {
    @apply w-full {} }

.login-download-section .module-description {
  @apply text-base text-secondary-700 font-normal {} }

.contact-info {
  @apply text-neutral-900 font-normal text-sm {} }
  .contact-info ul {
    @apply flex flex-col gap-3 {} }
  .contact-info li {
    @apply flex gap-3 {} }
    .contact-info li i, .contact-info li em {
      @apply w-5 text-secondary-500 text-lg {} }
    .contact-info li p {
      @apply flex-1 {} }

.contact-maps .iframe-scale {
  @apply pt-[calc(310/528*100%)] {} }

.contact-section {
  @apply overflow-hidden {} }
  .contact-section .wrap-form {
    @apply relative bg-neutral-100/50 p-12 z-1 {} }
  .contact-section .form-note {
    @apply body-4 {}    @apply mb-5 text-neutral-900 {} }
  .contact-section .row {
    @apply -mx-3 {} }
    .contact-section .row > * {
      @apply px-3 {} }

@screen xl {
  .contact-section .wrap-form {
    @apply -mt-20 pt-25 pb-15 bg-transparent {} }
    .contact-section .wrap-form::before {
      content: '';
      @apply bg-neutral-100/50 absolute top-0 left-0 w-screen h-full pointer-events-none -z-1 {} }
  .contact-section textarea {
    @apply h-[calc(200/1920*100rem)] {} }
  .contact-section .frm-btnwrap {
    @apply absolute bottom-15 right-12 {} } }

.experience-1-item {
  @apply py-12 {} }
  .experience-1-item .row {
    @apply md:items-center {} }
  .experience-1-item .image {
    @apply aspect-[16/10] {} }
  .experience-1-item .caption {
    @apply pt-5 md:pt-0 md:pl-5 xl:pl-12 {} }
  .experience-1-item .title {
    @apply site-semi-36 {}    @apply mb-5 text-secondary-600 {} }
  .experience-1-item .brief {
    @apply body-4 {}    @apply text-neutral-500 line-clamp-3 lg:line-clamp-6 {} }
  .experience-1-item .button {
    @apply mt-5 lg:mt-8 xl:mt-10 {} }

.experience-1-list .experience-1-item {
  @apply first:pt-0 last:pb-0 {} }
  .experience-1-list .experience-1-item:nth-child(even) {
    @apply bg-neutral-50 {} }
    .experience-1-list .experience-1-item:nth-child(even) .row {
      @apply md:flex-row-reverse {} }
    .experience-1-list .experience-1-item:nth-child(even) .caption {
      @apply md:pl-0 md:pr-5 xl:pr-12 {} }

.experience-item {
  @apply relative {} }
  .experience-item .image {
    @apply aspect-[16/10] img-cover {} }
  .experience-item .caption {
    @apply mt-5 {} }
  .experience-item .title {
    @apply body-1 {}    @apply text-neutral-900 font-bold line-clamp-2 hover:text-secondary-500 {} }
  .experience-item .brief {
    @apply body-4 {}    @apply mt-3 text-neutral-400 line-clamp-4 {} }
  .experience-item:hover .image img {
    @apply scale-110 {} }
  .experience-item.is-big .image::before {
    content: '';
    background: linear-gradient(180deg, rgba(112, 60, 0, 0) 0%, #703C00 100%);
    @apply absolute left-0 w-full bottom-0 h-1/2 z-1 pointer-events-none {} }

@screen md {
  .experience-item.is-big .caption {
    @apply absolute bottom-0 left-0 w-full z-2 p-5 {} }
  .experience-item.is-big .title {
    @apply text-white {} }
  .experience-item.is-big .brief {
    @apply text-white {} } }

.box-experience .box-head {
  @apply flex items-center gap-5 mb-10 justify-between {} }

.box-experience .box-title {
  @apply heading-5 {}  @apply font-light text-secondary-600 uppercase {} }

.box-experience .swiper-relative {
  @apply pb-10 lg:pb-0 {} }

.box-experience + .box-experience {
  @apply mt-10 xl:mt-15 {} }

header {
  @apply h-[90px] relative z-100 {} }
  header .header-fixed {
    @apply fixed bg-secondary-600 w-full top-0 left-0 transition-all {} }
  header .header-wrap {
    @apply flex items-center justify-between gap-3 xl:gap-5 {} }
  header .logo {
    @apply w-25 flex-center py-2 {} }
    header .logo a {
      @apply flex items-center {} }
    header .logo img {
      @apply max-w-full h-[74px] object-contain transition-all {} }
  header .header-right {
    @apply flex-1 flex items-center justify-end {} }
  header .navbar-nav {
    @apply hidden {} }
  header .main-menu {
    @apply flex items-center gap-6 2xl:gap-8 {} }
    header .main-menu .icon-toggle {
      @apply hidden {} }
    header .main-menu > li {
      @apply h-[90px] flex-center tsn {} }
      header .main-menu > li > a {
        @apply inline-block py-3 text-sm font-bold text-white leading-1.4 hover-underline {} }
      header .main-menu > li.active > a {
        @apply text-primary-500 hover-underline-active {} }
      header .main-menu > li.has-children {
        @apply relative {} }
        header .main-menu > li.has-children .sub-menu {
          @apply absolute top-full left-0 w-max bg-primary-500 rounded-2 py-5 px-6 flex flex-col gap-4 tsn opacity-0 pointer-events-none -translate-y-2 {} }
          header .main-menu > li.has-children .sub-menu a {
            @apply inline-block py-1 text-sm text-secondary-600 font-normal uppercase leading-1.4 hover-underline {} }
          header .main-menu > li.has-children .sub-menu li.active a {
            @apply hover-underline-active {} }
        header .main-menu > li.has-children:hover > a {
          @apply hover-underline-active {} }
        header .main-menu > li.has-children:hover .sub-menu {
          @apply opacity-100 pointer-events-auto translate-y-0 {} }
  header .button-group {
    @apply flex items-center ml-4 relative {} }
  header .button-language {
    @apply px-3 cursor-pointer {} }
    header .button-language .dropdown .dropdown-toggle {
      @apply h-10 flex-center gap-1 text-primary-500 {} }
    header .button-language .dropdown .current-language {
      @apply flex-center gap-1 text-xs font-normal text-secondary-300 {} }
    header .button-language .dropdown.active .current-language i {
      @apply rotate-180 {} }
  header .button-search {
    @apply hidden w-9 h-10 text-base text-primary-500 cursor-pointer tsn hover:text-primary-600 {} }
  header .button-contact {
    @apply hidden {} }
  header.active .logo img {
    @apply h-[54px] {} }
  header.active .header-fixed {
    @apply shadow {} }
  header.active .main-menu > li {
    @apply h-[70px] {} }

@screen md {
  header .button-contact {
    @apply flex {} } }

@screen xl {
  header .navbar-nav {
    @apply flex-1 flex items-center justify-end {} }
  header .button-group::before {
    content: '';
    @apply w-px h-5 bg-secondary-400 absolute top-1/2 -translate-y-1/2 left-0 {} }
  header .button-search {
    @apply flex-center {} } }

.mobile-wrap {
  @apply flex flex-col fixed top-0 w-screen h-screen bg-primary-600 z-999 pt-10 pl-4 pb-4 max-w-xs {}  @apply -left-full opacity-0 transition-all duration-500 ease-in-out pointer-events-none {} }
  .mobile-wrap .navbar-nav-list {
    @apply flex-1 pr-4 max-h-full h-full overflow-y-auto {} }
  .mobile-wrap .main-menu .icon-toggle {
    @apply absolute top-0 right-0 z-1 w-10 h-10 flex-center text-secondary-700 text-base tsn {} }
    .mobile-wrap .main-menu .icon-toggle.active {
      @apply -rotate-180 {} }
  .mobile-wrap .main-menu > li {
    @apply mt-3 {} }
    .mobile-wrap .main-menu > li > a {
      @apply inline-block py-2 text-lg font-medium text-secondary-700 hover-underline {} }
    .mobile-wrap .main-menu > li.active > a {
      @apply hover-underline-active {} }
    .mobile-wrap .main-menu > li.has-children {
      @apply relative {} }
      .mobile-wrap .main-menu > li.has-children .sub-menu {
        @apply pl-5 hidden {} }
        .mobile-wrap .main-menu > li.has-children .sub-menu a {
          @apply inline-block py-1 text-sm text-secondary-700 font-normal uppercase leading-1.4 {} }
        .mobile-wrap .main-menu > li.has-children .sub-menu li {
          @apply mt-1 {} }
          .mobile-wrap .main-menu > li.has-children .sub-menu li.active > a {
            @apply hover-underline-active {} }
  .mobile-wrap .searchbox {
    @apply ml-0 mb-5 mr-4 w-auto max-w-full {} }
    .mobile-wrap .searchbox input {
      @apply h-[40px] {} }
    .mobile-wrap .searchbox button {
      @apply text-secondary-700 {} }
  .mobile-wrap .button-contact {
    @apply mt-5 {} }
  .mobile-wrap.open {
    @apply left-0 opacity-100 shadow pointer-events-auto {} }

@screen xl {
  .mobile-wrap {
    @apply hidden {} } }

.searchbox {
  @apply relative {} }
  .searchbox input {
    @apply w-full h-10 border-neutral-300 border rounded-1 pl-5 pr-10 text-base text-neutral-900 placeholder:text-neutral-500 focus:outline-none focus:border-primary-700 {} }
  .searchbox button {
    @apply absolute top-1/2 -translate-y-1/2 right-1 z-1 w-10 h-10 flex-center text-xl text-primary-600 {} }

@screen xl {
  .searchbox {
    @apply container {}    @apply px-0 {} } }

.search-wrap {
  @apply fixed top-1/4 left-1/2 -translate-x-1/2 w-full max-w-lg bg-neutral-900 shadow z-999 px-3 py-2 rounded-1 border border-neutral-400 transition-all duration-300 ease-in-out {}  @apply opacity-0 pointer-events-none {} }
  .search-wrap .searchbox input {
    @apply bg-transparent border-none text-white {}    @apply placeholder:text-neutral-400 placeholder:font-light {} }
  .search-wrap .searchbox button {
    @apply text-white {} }
  .search-wrap.open {
    @apply opacity-100 pointer-events-auto {} }

#buttonMenu {
  @apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none m-0 ml-5 p-0 bg-none {} }
  #buttonMenu .line {
    @apply absolute block h-[2px] w-full bg-white rounded-full opacity-100 left-0 rotate-0 transition-all {} }
    #buttonMenu .line:nth-child(1) {
      @apply top-[4px] {} }
    #buttonMenu .line:nth-child(2) {
      @apply top-1/2 -translate-y-1/2 {} }
    #buttonMenu .line:nth-child(3) {
      @apply bottom-[4px] {} }
  #buttonMenu #pulseMe {
    @apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1 {} }
  #buttonMenu .bar {
    @apply bg-white rounded-1 absolute shadow-bar {} }
    #buttonMenu .bar.left {
      @apply w-px animate-left-bar {} }
    #buttonMenu .bar.top {
      @apply h-px animate-top-bar {} }
    #buttonMenu .bar.right {
      @apply w-px animate-right-bar {} }
    #buttonMenu .bar.bottom {
      @apply h-px animate-bottom-bar {} }
  #buttonMenu.open .line:nth-child(2), #buttonMenu:hover .line:nth-child(2) {
    @apply w-[15px] {} }
  #buttonMenu.open .bar, #buttonMenu:hover .bar {
    @apply hidden {} }

@screen xl {
  #buttonMenu {
    display: none !important; } }

.library-image-item .image {
  @apply relative aspect-[16/10] img-cover img-zoom {} }

.library-image-item .title {
  @apply body-2 {}  @apply mt-5 font-normal text-neutral-900 text-center {} }

@screen xl {
  .library-image-item .image::before {
    content: '';
    @apply w-full h-full bg-black/50 absolute-center z-1 pointer-events-none tsn opacity-0 {} }
  .library-image-item .image::after {
    @apply font-awesome content-['\f002'] font-light text-white text-2xl absolute-center z-2 pointer-events-none tsn opacity-0 delay-100 {} }
  .library-image-item:hover .image {
    @apply before:opacity-100 after:opacity-100 {} } }

.library-video-item .image {
  @apply relative aspect-[16/10] img-cover {} }
  .library-video-item .image::before {
    content: '';
    @apply absolute-center w-full h-full bg-secondary-600/50 pointer-events-none z-1 tsn {} }
  .library-video-item .image::after {
    @apply font-awesome content-['\f04b'] text-4xl font-light leading-none text-secondary-600 w-15 h-15 rounded-full flex-center bg-primary-500 absolute-center z-2 tsn pointer-events-none xl:w-20 xl:h-20 xl:text-6xl {} }

.library-video-item .title {
  @apply body-2 {}  @apply font-bold text-white absolute bottom-0 left-0 w-full px-5 py-6 z-5 text-center line-clamp-2 xl:py-20 {} }

.library-video-item:hover .image {
  @apply after:scale-110 {} }

.video-top-swiper {
  @apply pb-10 {} }

@screen md {
  .video-top-swiper {
    @apply pb-0 {} }
    .video-top-swiper .swiper-pagination {
      @apply hidden {} } }

.video-thumb-swiper {
  @apply hidden {} }
  .video-thumb-swiper .library-video-item .image {
    @apply before:bg-secondary-400/50 {} }
    .video-thumb-swiper .library-video-item .image::after {
      @apply text-base w-9 h-9 top-[35%] {} }
  .video-thumb-swiper .library-video-item .title {
    @apply py-4 px-2 {} }
  .video-thumb-swiper .library-video-item:hover .image {
    @apply after:scale-100 {} }
  .video-thumb-swiper .swiper-slide-thumb-active .library-video-item .image {
    @apply before:bg-secondary-600/50 {} }

@screen md {
  .video-thumb-swiper {
    @apply block px-5 {} }
    .video-thumb-swiper .swiper-button {
      @apply block {} }
      .video-thumb-swiper .swiper-button.is-absolute .button-prev {
        @apply -left-5 {} }
      .video-thumb-swiper .swiper-button.is-absolute .button-next {
        @apply -right-5 {} } }

@screen xl {
  .video-thumb-swiper {
    @apply px-0 {} }
    .video-thumb-swiper .swiper-button.is-absolute > * {
      @apply left-1/2 -translate-x-1/2 translate-y-0 rotate-90 w-max {} }
    .video-thumb-swiper .swiper-button.is-absolute .button-prev {
      @apply -top-11 left-1/2 {} }
    .video-thumb-swiper .swiper-button.is-absolute .button-next {
      @apply top-auto -bottom-11 {} } }

.library-video-wrap {
  @apply flex flex-col gap-5 {} }

@screen xl {
  .library-video-wrap {
    @apply pt-6 flex-row {} }
    .library-video-wrap .library-video-item {
      @apply h-full {} }
      .library-video-wrap .library-video-item .image {
        @apply h-full {} }
        .library-video-wrap .library-video-item .image img {
          @apply h-full {} }
    .library-video-wrap .video-top-swiper {
      @apply w-[calc(1053/1312*100%)] {} }
      .library-video-wrap .video-top-swiper .swiper {
        @apply h-full {} }
    .library-video-wrap .video-thumb-swiper {
      @apply flex-1 h-[656px] {} }
      .library-video-wrap .video-thumb-swiper .swiper {
        @apply h-full {} }
      .library-video-wrap .video-thumb-swiper .swiper-slide {
        @apply h-[150px] {} } }

.maps-section .global-desc {
  @apply site-regular-20 {}  @apply font-secondary text-neutral-500 {} }

.home-1-section {
  @apply relative overflow-hidden z-1 bg-primary-50 {} }

@screen xl {
  .home-1-section::after {
    content: '';
    @apply absolute top-[40%] -translate-y-1/2 right-0 w-[calc(294/1920*100rem)] h-[calc(353/1920*100rem)] bg-[url(../img/bird-right.png)] bg-contain bg-center -z-1 {} }
  .home-1-section .about-count-list {
    @apply grid-cols-4 {} }
    .home-1-section .about-count-list .about-count-item::after {
      content: '';
      @apply absolute top-1/2 -translate-y-1/2 right-0 w-px h-full bg-neutral-100 pointer-events-none {} }
    .home-1-section .about-count-list .about-count-item:nth-child(2n) {
      @apply after:block {} }
    .home-1-section .about-count-list .about-count-item:nth-child(n+3) {
      @apply mt-0 before:hidden {} }
    .home-1-section .about-count-list .about-count-item:last-child {
      @apply after:hidden {} } }

.home-2-section .global-desc {
  @apply site-regular-20 {}  @apply text-neutral-900 {} }

.home-experience-item {
  @apply relative rounded-4 overflow-hidden {} }
  .home-experience-item .image {
    @apply aspect-square img-cover {} }
  .home-experience-item .caption {
    @apply absolute top-0 left-0 w-full p-5 z-1 bg-gradient-to-b from-black/50 to-black/0 xl:p-6 xl:min-h-[calc(200/1920*100rem)] transition-all pointer-events-none {} }
  .home-experience-item .category {
    @apply body-4 {}    @apply text-white pb-2 mb-2 relative pointer-events-auto {} }
    .home-experience-item .category::before {
      content: '';
      @apply absolute bottom-0 left-0 w-10 h-px bg-white pointer-events-none {} }
  .home-experience-item .title {
    @apply body-1 {}    @apply text-white font-bold pointer-events-auto {} }
    .home-experience-item .title a {
      @apply hover-underline {} }
  .home-experience-item:hover .caption {
    @apply h-full {} }

.home-3-section .about-4-section {
  @apply mb-0 {} }

.box-home .box-head {
  @apply flex flex-wrap justify-between gap-5 {} }

.home-4-swiper {
  @apply pb-10 xl:pb-0 {} }

.home-5-item {
  @apply relative overflow-hidden z-1 bg-white {} }
  .home-5-item .site-title {
    @apply mb-5 text-secondary-600 {} }
  .home-5-item .site-desc {
    @apply mb-5 text-neutral-900 font-normal {} }
  .home-5-item .global-desc {
    @apply mb-5 text-neutral-500 font-normal {} }
  .home-5-item .button {
    @apply mt-5 {} }
  .home-5-item .image {
    @apply aspect-[16/10] img-cover {} }
  .home-5-item:nth-child(odd) {
    @apply bg-neutral-50 {} }
  .home-5-item:nth-child(even) {
    @apply bg-secondary-700 {} }
    .home-5-item:nth-child(even) .site-title, .home-5-item:nth-child(even) .site-desc, .home-5-item:nth-child(even) .global-desc {
      @apply text-white {} }
    .home-5-item:nth-child(even) .button a {
      @apply text-white border-white {} }
  .home-5-item:first-child {
    @apply bg-white {} }

@screen lg {
  .home-5-item:nth-child(2n) .box-home-5 {
    @apply flex-row-reverse {} } }

@screen xl {
  .home-5-item {
    @apply py-25 {} }
    .home-5-item .box-home-5 {
      @apply min-h-[calc(600/1920*100rem)] mt-0 {} }
      .home-5-item .box-home-5 > * {
        @apply mt-0 {} }
    .home-5-item .image {
      @apply absolute left-1/2 top-1/2 -translate-y-1/2 h-full max-h-[calc(600/1920*100rem)] {} }
    .home-5-item:nth-child(1)::after {
      content: '';
      @apply absolute top-[40%] -translate-y-1/2 left-7 w-[calc(287/1920*100rem)] h-[calc(370/1920*100rem)] bg-[url(../img/bird-left.png)] bg-contain bg-center -z-1 {} }
    .home-5-item:nth-child(2n) .image {
      @apply left-auto right-1/2 {} }
    .home-5-item:nth-child(2n)::after {
      content: '';
      @apply absolute -bottom-[calc(155/1920*100rem)] -right-[calc(76/1920*100rem)] w-[calc(396/1920*100rem)] h-[calc(602/1920*100rem)] bg-[url(../img/gastronomy.png)] bg-contain bg-center -z-1 {} }
    .home-5-item:nth-child(3)::after {
      content: '';
      @apply absolute bottom-0 left-0 w-[calc(236/1920*100rem)] h-[calc(399/1920*100rem)] bg-[url(../img/pine.png)] bg-contain bg-center -z-1 {} } }

.home-6-item {
  @apply relative {} }
  .home-6-item .image {
    @apply relative aspect-[16/12.5] img-cover w-full h-full {} }
    .home-6-item .image::before {
      content: '';
      @apply bg-black/50 absolute-center w-full h-full z-1 pointer-events-none tsn {} }
  .home-6-item .caption {
    @apply relative p-5 flex flex-col justify-between gap-5 bg-white {} }
  .home-6-item .title {
    @apply site-bold-24 {}    @apply text-neutral-900 font-bold mb-5 hover:text-secondary-600 {} }
  .home-6-item .brief {
    @apply body-4 {}    @apply text-neutral-500 font-normal {} }
  .home-6-item .view-all {
    @apply text-secondary-600 hover:text-primary-600 {} }

@screen md {
  .home-6-item:first-child .caption {
    @apply absolute bottom-0 left-0 px-15 py-6 z-2 gap-4 bg-transparent {} }
  .home-6-item:first-child .title, .home-6-item:first-child .brief, .home-6-item:first-child .view-all {
    @apply text-white {} }
  .home-6-item:first-child .button {
    @apply mt-6 {} }
  .home-6-item:first-child .view-all {
    @apply inline-block {} }
  .home-6-item:nth-child(n+2) {
    @apply flex {} }
    .home-6-item:nth-child(n+2) > * {
      @apply w-1/2 {} }
    .home-6-item:nth-child(n+2) .caption {
      @apply p-10 {} }
      .home-6-item:nth-child(n+2) .caption::before {
        content: '';
        @apply absolute top-1/2 -translate-y-1/2 right-full rotate-180 z-1 border-solid border-l-[20px] border-y-[30px] border-l-white border-y-transparent w-0 h-0 pointer-events-none {} }
  .home-6-item:nth-child(2n) {
    @apply flex-row-reverse {} }
    .home-6-item:nth-child(2n) .caption {
      @apply before:rotate-0 before:left-full {} } }

@screen xl {
  .home-6-item .image {
    @apply hover:before:opacity-0 {} }
  .home-6-item:nth-child(n+2) .caption {
    @apply p-15 {} } }

.home-6-wrap {
  @apply px-5 md:px-5 lg:px-10 xl:px-0 {} }

@screen xl {
  .home-6-wrap {
    @apply grid grid-cols-2 {} }
    .home-6-wrap .home-6-item:nth-child(1) {
      @apply row-span-2 {} } }

.home-6-section {
  @apply pb-0 {} }

.home-7-section .news-nav {
  @apply mt-5 {} }
  .home-7-section .news-nav .nav-list {
    @apply justify-start mb-0 {} }

.home-7-section .news-list {
  @apply mt-10 xl:mt-15 {} }

.home-8-section {
  @apply relative md:pb-0 lg:py-0 {} }
  .home-8-section .site-desc {
    @apply text-neutral-900 {} }
  .home-8-section .global-desc {
    @apply text-neutral-500 {} }

@screen lg {
  .home-8-section .home-8-flex {
    @apply flex {} }
    .home-8-section .home-8-flex > * {
      @apply w-1/2 {} }
  .home-8-section .home-8-content {
    @apply py-20 {} }
  .home-8-section .home-8-video {
    @apply relative z-2 {} } }

@screen xl {
  .home-8-section .home-8-flex {
    @apply justify-end {} }
  .home-8-section .home-8-content {
    @apply absolute top-1/2 -translate-y-1/2 left-0 w-full z-1 {} }
  .home-8-section .global-desc {
    @apply max-w-[calc(575/1920*100rem)] {} } }

.home-review-item {
  @apply flex flex-col gap-5 {} }
  .home-review-item .image {
    @apply w-[calc(300/1920*100rem)] mx-auto {} }
  .home-review-item .avatar {
    @apply aspect-square rounded-full img-cover overflow-hidden {} }
  .home-review-item .caption {
    @apply flex-1 {} }
  .home-review-item .icon {
    @apply text-6xl text-secondary-600 mb-1 leading-none {} }
  .home-review-item .title, .home-review-item .name {
    @apply site-bold-24 {}    @apply text-neutral-900 font-bold mb-5 {} }
  .home-review-item .brief {
    @apply body-4 {}    @apply text-neutral-500 font-normal {} }
  .home-review-item .author {
    @apply mt-8 pt-4 relative xl:mt-11 {} }
    .home-review-item .author::before {
      content: '';
      @apply absolute top-0 left-0 w-20 h-px bg-secondary-600 {} }
  .home-review-item .name {
    @apply text-secondary-600 mb-2 {} }
  .home-review-item .address {
    @apply body-2 {}    @apply text-neutral-500 font-normal {} }

@screen lg {
  .home-review-item {
    @apply flex-row gap-8 {} } }

.home-9-swiper {
  @apply pb-10 xl:pb-0 {} }

@screen xl {
  .home-9-section {
    @apply py-25 {} } }

.home-10-section .maps-section {
  @apply pb-0 {} }

.news-other-wrap .news-item {
  @apply py-5 border-b border-b-neutral-100 first:pt-0 {} }

.news-detail-wrap {
  @apply flex flex-col gap-5 relative {} }
  .news-detail-wrap .news-detail-head {
    @apply flex flex-col gap-5 border-b border-b-neutral-100 pb-5 {} }
  .news-detail-wrap .full-content > * {
    @apply first:mt-0 {} }

@screen xl {
  .news-detail-section .social-list {
    @apply absolute top-0 -left-25 flex-col m-0 {} } }

.toc-wrap {
  @apply bg-primary-100 p-4 rounded-2 border border-secondary-400 mb-10 {} }
  .toc-wrap .toc-title {
    @apply text-sm font-medium text-secondary-600 flex items-center justify-between gap-2 {} }
  .toc-wrap .toc-toggle {
    @apply cursor-pointer {} }
    .toc-wrap .toc-toggle.active {
      @apply rotate-180 {} }
  .toc-wrap .js-toc {
    @apply mt-2 {} }
  .toc-wrap .js-toc {
    @apply text-sm font-normal text-secondary-600 {} }
    .toc-wrap .js-toc ol, .toc-wrap .js-toc ul {
      @apply pl-6 {} }
    .toc-wrap .js-toc .toc-list {
      list-style-type: upper-roman; }
      .toc-wrap .js-toc .toc-list .toc-list {
        list-style-type: lower-alpha; }
        .toc-wrap .js-toc .toc-list .toc-list .toc-list {
          list-style-type: decimal; }
    .toc-wrap .js-toc a {
      @apply leading-normal hover-underline {} }
  .toc-wrap .toc-h3 {
    @apply pl-3 {} }

.news-date {
  @apply text-xs font-normal text-neutral-300 leading-1.33 {} }

.news-item {
  @apply relative {} }
  .news-item .image {
    @apply aspect-[16/10.5] img-cover {} }
  .news-item .caption {
    @apply p-5 flex flex-col gap-3 {} }
  .news-item .date-cate {
    @apply flex flex-wrap items-center gap-5 {} }
    .news-item .date-cate > * {
      @apply relative last:after:hidden {} }
      .news-item .date-cate > *::after {
        content: '';
        @apply absolute top-1/2 -translate-y-1/2 -right-3 block w-[2px] h-[2px] bg-neutral-400 {} }
  .news-item .cate {
    @apply text-xs font-bold text-neutral-300 leading-1.33 {} }
  .news-item .title {
    @apply text-base font-bold text-neutral-900 line-clamp-5 hover:text-secondary-600 {} }
  .news-item .brief {
    @apply body-2 {}    @apply text-neutral-900 line-clamp-3 {} }
  .news-item .button a {
    @apply text-xl text-secondary-600 {} }
  .news-item:hover .image img {
    @apply scale-110 {} }

@screen md {
  .news-item.is-big .caption {
    @apply absolute left-0 w-full bottom-0 bg-gradient-to-b from-black/0 to-black/50 xl:p-6 {} }
  .news-item.is-big .title {
    text-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    @apply line-clamp-2 text-white text-xl {} }
    .news-item.is-big .title a {
      @apply hover-underline {} }
  .news-item.is-big .news-date, .news-item.is-big .cate {
    @apply text-white {} }
  .news-item.is-big .button a {
    @apply text-white {} } }
  .news-item.is-other {
    @apply flex gap-5 {} }
    .news-item.is-other .image {
      @apply w-[192px] {} }
    .news-item.is-other .caption {
      @apply flex-1 p-0 {} }
    .news-item.is-other .title {
      @apply text-base font-normal line-clamp-3 {} }
  .news-item.is-promotion .image {
    @apply aspect-[16/8.5] {} }
  .news-item.is-promotion .caption {
    @apply px-0 {} }

.nav-list {
  @apply flex gap-5 mb-10 xl:gap-10 xl:justify-center {} }
  .nav-list li {
    @apply w-max {} }
    .nav-list li a {
      @apply body-2 {}      @apply whitespace-nowrap text-neutral-400 hover:text-secondary-600 p-2 inline-block hover-underline {} }
    .nav-list li.active a {
      @apply text-secondary-600 {} }

.promotion-detail-section .box-head {
  @apply flex items-center gap-5 flex-wrap justify-between mb-10 {} }

.promotion-detail-section .promotion-info {
  @apply mb-10 {} }
  .promotion-detail-section .promotion-info table {
    @apply w-full {} }
  .promotion-detail-section .promotion-info td {
    @apply border-y-2 {}    @apply py-3 border-y border-y-neutral-100 pr-5 align-top {} }
    .promotion-detail-section .promotion-info td:first-child {
      @apply font-bold w-1/3 md:w-[calc(304/1312*100%)] {} }

.promotion-detail-section .full-content {
  @apply body-2 {}  @apply text-neutral-900 {} }

.promotion-other-wrap {
  @apply mt-10 xl:mt-15 {} }
  .promotion-other-wrap .promotion-other-swiper {
    @apply pb-10 xl:pb-0 {} }

.room-equipment-item .image {
  @apply aspect-square rounded-4 overflow-hidden {} }

.room-equipment-item .caption {
  @apply mt-5 {} }

.room-equipment-item .title {
  @apply site-bold-24 {}  @apply text-neutral-900 {} }

.room-equipment-item .brief {
  @apply body-4 {}  @apply mt-2 text-neutral-900 {} }

.room-service-item .image {
  @apply aspect-[16/9] {} }

.room-service-item .title {
  @apply body-2 {}  @apply mt-5 text-center text-neutral-900 font-normal {} }

.room-detail-section .room-item {
  @apply mt-9 {} }
  .room-detail-section .room-item .button {
    @apply flex mt-4 {} }

@screen lg {
  .room-detail-section .room-item .caption {
    @apply py-0 {} }
  .room-detail-section .room-item .title {
    @apply mb-4 {} } }

.room-detail-section .room-equipment-wrap {
  @apply mt-15 {} }

.room-detail-section .room-service-wrap {
  @apply mt-15 {} }

.news-book-now .book-now-title {
  @apply site-bold-24 {}  @apply text-center text-white mb-5 {} }

.news-book-now .global-desc {
  @apply text-center text-white {} }

.news-book-now .button {
  @apply mt-10 {} }

.room-item .image {
  @apply aspect-[16/9] {} }

.room-item .caption {
  @apply bg-white p-5 lg:p-8 xl:px-8 xl:py-8 {} }

.room-item .title {
  @apply site-bold-24 {}  @apply text-neutral-900 mb-2 hover:text-secondary-500 {} }

.room-item .price-wrap {
  @apply flex flex-col gap-4 mb-4 {} }
  .room-item .price-wrap .price {
    @apply mb-2 {} }
  .room-item .price-wrap .note {
    @apply body-4 {}    @apply text-neutral-400 {} }

.room-item .price {
  @apply heading-4 {}  @apply font-light text-secondary-600 mb-2 xl:mb-4 {} }
  .room-item .price .unit {
    @apply body-2 {}    @apply font-bold ml-2 {} }

.room-item .global-desc {
  @apply body-4 {}  @apply text-neutral-500 mb-5 line-clamp-3 xl:mb-8 {} }
  .room-item .global-desc * {
    @apply first:mt-0 {} }

.room-item .facilities {
  @apply flex flex-wrap gap-y-5 {} }
  .room-item .facilities li {
    @apply body-4 {}    @apply flex gap-2 text-neutral-500 pr-2 w-full md:w-1/2 {} }
    .room-item .facilities li .icon {
      @apply flex-center w-8 text-2xl text-secondary-500 md:w-6 {} }

.room-item .button {
  @apply hidden mt-6 {} }

.room-item .view-all {
  @apply text-secondary-600 text-2xl {} }

@screen lg {
  .room-item .container, .room-item .room-flex {
    @apply flex {} }
  .room-item .image {
    @apply w-[calc(752/1312*100%)] {} }
  .room-item .caption {
    @apply flex-1 {} } }

.room-item.is-room-other {
  @apply block {} }
  .room-item.is-room-other .image {
    @apply w-full {} }
  .room-item.is-room-other .caption {
    @apply p-0 mt-5 {} }
  .room-item.is-room-other .title, .room-item.is-room-other .global-desc, .room-item.is-room-other .facilities {
    @apply mb-4 {} }

.room-list .room-item {
  @apply bg-neutral-50 py-10 xl:py-15 {} }
  .room-list .room-item:nth-child(even) {
    @apply bg-white {} }
    .room-list .room-item:nth-child(even) .caption {
      @apply bg-neutral-50 {} }
    .room-list .room-item:nth-child(even) .facilities li:nth-child(n+7) {
      @apply hidden {} }
    .room-list .room-item:nth-child(even) .button {
      @apply flex {} }

@screen lg {
  .room-list .room-item:nth-child(even) .container {
    @apply flex-row-reverse {} } }

.room-section .block-title {
  @apply pb-8 bg-neutral-50 pt-15 xl:pt-20 xl:pb-5 {} }
  .room-section .block-title .global-desc {
    @apply font-secondary text-neutral-900 {} }

.room-section .room-item {
  @apply last:mb-15 xl:last:mb-20 {} }

.room-section .global-desc {
  @apply site-regular-20 {} }

.sitemap {
  @apply py-15 {} }

.sitemap-heading h1 {
  @apply global-title text-center uppercase mx-auto {}  @apply relative w-max max-w-full pb-2 before:absolute before:bottom-0 before:left-0 before:w-full before:h-px before:bg-main {} }

.AspNet-TreeView > ul {
  @apply sm:flex sm:flex-wrap xl:overflow-hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root {
  @apply pb-5 ml-2 first:ml-0 xl:ml-0 xl:pr-5 xl:relative xl:overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root a {
    @apply inline-flex items-center px-7 py-3 text-xl bg-blue-500 text-white rounded-full max-w-full relative z-2 xl:text-sm xl:px-5 xl:hover:bg-secondary-700 {} }
  .AspNet-TreeView .AspNet-TreeView-Root > a {
    @apply before:absolute before:left-full before:w-[120%] before:h-px before:bg-neutral-900 before:-z-1 {} }
  .AspNet-TreeView .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
    @apply hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root li {
    @apply pl-3 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li a {
      @apply bg-blue-500/90 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li a {
      @apply bg-blue-500/75 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li li a {
      @apply bg-blue-500/60 {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li:last-child {
    @apply before:absolute before:w-px before:h-full before:left-0 before:top-[65%] before:-translate-y-1/2 before:bg-white before:z-1 before:pointer-events-none {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Leaf:last-child {
    @apply before:top-full {} }
  .AspNet-TreeView .AspNet-TreeView-Root:last-child > a {
    @apply before:hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root > ul, .AspNet-TreeView .AspNet-TreeView-Leaf > ul, .AspNet-TreeView .AspNet-TreeView-Parent > ul {
  @apply ml-5 overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li {
    @apply mt-2 relative z-1 {}    @apply after:absolute after:w-px after:h-[200%] after:left-0 after:top-1/2 after:-translate-y-full after:bg-neutral-900 after:pointer-events-none {} }
    .AspNet-TreeView .AspNet-TreeView-Root > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li > a {
      @apply relative {}      @apply before:absolute before:w-3 before:-z-1 before:h-px before:bg-neutral-900 before:top-1/2 before:-translate-y-1/2 before:right-full before:pointer-events-none {} }

.footer-top {
  @apply py-10 {} }

.footer-title {
  @apply text-base font-bold text-neutral-900 leading-1.4 mb-2 xl:text-sm {} }

.footer-content {
  @apply text-sm font-normal text-neutral-900 leading-1.33 break-words xl:text-xs {} }
  .footer-content address {
    @apply block not-italic {} }
  .footer-content a {
    @apply text-secondary-400 font-bold hover-underline hover:text-primary-600 {} }
  .footer-content * + h4 {
    @apply mt-4 {} }
  .footer-content + * {
    @apply mt-4 {} }

.footer-bot {
  @apply bg-secondary-600 py-6 {} }
  .footer-bot .footer-wrap {
    @apply flex flex-col gap-4 lg:flex-row lg:justify-between {} }
  .footer-bot .footer-left {
    @apply text-sm text-neutral-white leading-1.33 xl:text-xs {} }
  .footer-bot .footer-right ul {
    @apply text-sm text-neutral-white leading-1.33 flex flex-col gap-4 md:flex-row md:flex-wrap xl:text-xs {} }
    .footer-bot .footer-right ul li {
      @apply lg:pr-4 lg:border-r lg:border-r-white lg:last:pr-0 lg:last:border-none {} }
    .footer-bot .footer-right ul a {
      @apply hover-underline {} }

footer {
  @apply bg-neutral-50 {} }
