.experience-1-item
	@apply py-12
	.row
		@apply md:items-center
	.image
		@apply aspect-[16/10]
	.caption
		@apply pt-5 md:pt-0 md:pl-5 xl:pl-12
	.title
		@apply site-semi-36
		@apply mb-5 text-secondary-600
	.brief
		@apply body-4
		@apply text-neutral-500 line-clamp-3 lg:line-clamp-6
	.button
		@apply mt-5 lg:mt-8 xl:mt-10

.experience-1-list
	.experience-1-item
		@apply first:pt-0 last:pb-0
		&:nth-child(even)
			@apply bg-neutral-50
			.row
				@apply md:flex-row-reverse
			.caption
				@apply md:pl-0 md:pr-5 xl:pr-12
