.about-count-item
	@apply py-5 relative
	.count-number
		@apply heading-4
		@apply text-primary-500
		.count-item
			@apply pr-2 font-bold
		.sub-number
			@apply heading-5 font-normal
	.title
		@apply body-4
		@apply mt-3 font-bold text-neutral-500
	@screen sm
		@apply py-7 px-8

.about-count-list
	@apply grid grid-cols-2 gap-x-5 overflow-hidden mx-auto xl:gap-x-8
	.about-count-item
		&::after
			content: ''
			@apply absolute top-1/2 -translate-y-1/2 right-0 w-px h-full bg-neutral-100 pointer-events-none
		&:nth-child(2n)
			@apply after:hidden
		&:nth-child(n+3)
			@apply mt-10
			&::before
				content: ''
				@apply absolute left-1/2 -translate-x-1/2 -top-5 w-[calc(100%+40px)] h-px bg-neutral-100 pointer-events-none

.about-1-section
	@apply relative overflow-hidden z-1 bg-primary-50
	@screen xl
		&::after
			content: ''
			@apply absolute top-[40%] -translate-y-1/2 right-0 w-[calc(294/1920*100rem)] h-[calc(353/1920*100rem)] bg-[url(../img/bird-right.png)] bg-contain bg-center -z-1

.about-2-item
	.title
		@apply site-semi-24
		@apply text-primary-500
	.global-desc
		@apply body-2
		@apply mt-7 text-white font-normal

.about-2-section
	@apply pt-15 pb-20 md:pb-[calc(200/1920*100rem)]
	.swiper-relative
		@apply max-w-[calc(528/1920*100rem)] bg-secondary-700/60 backdrop-blur-[12px] px-7 py-8 mt-10 xl:px-8 xl:py-15
	.swiper-button
		@apply flex items-center gap-8
		> *
			@apply text-white text-2xl

.about-3-item
	.image
		@apply aspect-[16/9]
	.title
		@apply site-bold-24
		@apply text-secondary-500 mb-5
	.button
		@apply mt-5

.about-3-section
	@apply relative z-1 overflow-hidden
	.swiper
		@apply overflow-visible pb-10 xl:pb-20
	.swiper-scrollbar
		@apply hidden xl:block
	.swiper-pagination
		@apply xl:hidden
	@screen xl
		@apply mb-20
		&::after
			content: ''
			@apply absolute bottom-0 -right-[calc(198/1920*100rem)] w-[calc(396/1920*100rem)] h-[calc(602/1920*100rem)] bg-[url(../img/gastronomy.png)] bg-contain bg-center -z-1 opacity-30

.about-4-item
	@apply flex flex-col justify-between items-center bg-white rounded-4 p-6 gap-6 xl:py-8
	.top
		@apply flex flex-col justify-center items-center gap-6
	.icon
		@apply w-16 h-16 mx-auto flex-center
	.title
		@apply body-4
		@apply text-neutral-500 text-center
	.view-all
		@apply body-5
		@apply text-center font-bold text-secondary-300 uppercase hover:text-secondary-600

.about-4-section
	.about-4-swiper
		@apply pb-10 xl:pb-0
	@screen xl
		@apply mb-20

.about-5-section
	@apply min-h-[calc(640/1920*100rem)] relative z-1
	@media(max-width: 767.89px)
		background-position: 0 0 !important
	@screen md
		&::before
			content: ''
			@apply absolute top-0 right-1/2 bg-gradient-to-r from-white to-white/0 w-1/2 h-full -z-1 pointer-events-none
		.image
			@apply hidden
	@screen xl
		@apply mb-20
