.library-video-item
	.image
		@apply relative aspect-[16/10] img-cover
		&::before
			content: ''
			@apply absolute-center w-full h-full bg-secondary-600/50 pointer-events-none z-1 tsn
		&::after
			@apply font-awesome content-['\f04b'] text-4xl font-light leading-none text-secondary-600 w-15 h-15 rounded-full flex-center bg-primary-500 absolute-center z-2 tsn pointer-events-none xl:w-20 xl:h-20 xl:text-6xl
	.title
		@apply body-2
		@apply font-bold text-white absolute bottom-0 left-0 w-full px-5 py-6 z-5 text-center line-clamp-2 xl:py-20
	&:hover
		.image
			@apply after:scale-110

.video-top-swiper
	@apply pb-10
	@screen md
		@apply pb-0
		.swiper-pagination
			@apply hidden

.video-thumb-swiper
	@apply hidden
	.library-video-item
		.image
			@apply before:bg-secondary-400/50
			&::after
				@apply text-base w-9 h-9 top-[35%]
		.title
			@apply py-4 px-2
		&:hover
			.image
				@apply after:scale-100
	.swiper-slide-thumb-active
		.library-video-item
			.image
				@apply before:bg-secondary-600/50
	@screen md
		@apply block px-5
		.swiper-button
			@apply block
			&.is-absolute
				.button-prev
					@apply -left-5
				.button-next
					@apply -right-5
	@screen xl
		@apply px-0
		.swiper-button
			&.is-absolute
				> *
					@apply left-1/2 -translate-x-1/2 translate-y-0 rotate-90 w-max
				.button-prev
					@apply -top-11 left-1/2
				.button-next
					@apply top-auto -bottom-11

.library-video-wrap
	@apply flex flex-col gap-5
	@screen xl
		@apply pt-6 flex-row
		.library-video-item
			@apply h-full
			.image
				@apply h-full
				img
					@apply h-full
		.video-top-swiper
			@apply w-[calc(1053/1312*100%)]
			.swiper
				@apply h-full
		.video-thumb-swiper
			@apply flex-1 h-[656px]
			.swiper
				@apply h-full
			.swiper-slide
				@apply h-[150px]
