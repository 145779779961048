.library-image-item
	.image
		@apply relative aspect-[16/10] img-cover img-zoom
	.title
		@apply body-2
		@apply mt-5 font-normal text-neutral-900 text-center
	@screen xl
		.image
			&::before
				content: ''
				@apply w-full h-full bg-black/50 absolute-center z-1 pointer-events-none tsn opacity-0
			&::after
				@apply font-awesome content-['\f002'] font-light text-white text-2xl absolute-center z-2 pointer-events-none tsn opacity-0 delay-100
		&:hover
			.image
				@apply before:opacity-100 after:opacity-100
