.banner-child
	@apply relative
	.image
		@apply aspect-square relative
		&::before
			content: ''
			@apply absolute-center bg-black/20 w-full h-full pointer-events-none
		img
			@apply w-full h-full object-cover
	.banner-title
		@apply site-semi-48 text-white
	.banner-brief
		@apply mt-3 heading-4 text-white font-light
	.caption
		@apply absolute bottom-0 left-0 w-full py-10 z-1
	@screen md
		.image
			@apply aspect-[16/5]
		.caption
			@apply py-10
	@screen xl
		.caption
			@apply pt-10 pb-25

.news-detail-page
	.banner-child
		@apply hidden

.news-detail-page
	.promotion-banner
		.banner-child
			@apply block
