.room-item
	.image
		@apply aspect-[16/9]
	.caption
		@apply bg-white p-5 lg:p-8 xl:px-8 xl:py-8
	.title
		@apply site-bold-24
		@apply text-neutral-900 mb-2 hover:text-secondary-500
	.price-wrap
		@apply flex flex-col gap-4 mb-4
		.price
			@apply mb-2
		.note
			@apply body-4
			@apply text-neutral-400
	.price
		@apply heading-4
		@apply font-light text-secondary-600 mb-2 xl:mb-4
		.unit
			@apply body-2
			@apply font-bold ml-2
	.global-desc
		@apply body-4
		@apply text-neutral-500 mb-5 line-clamp-3 xl:mb-8
		*
			@apply first:mt-0
	.facilities
		@apply flex flex-wrap gap-y-5
		li
			@apply body-4
			@apply flex gap-2 text-neutral-500 pr-2 w-full md:w-1/2
			.icon
				@apply flex-center w-8 text-2xl text-secondary-500 md:w-6
	.button
		@apply hidden mt-6
	.view-all
		@apply text-secondary-600 text-2xl
	@screen lg
		.container, .room-flex
			@apply flex
		.image
			@apply w-[calc(752/1312*100%)]
		.caption
			@apply flex-1
	&.is-room-other
		@apply block
		.image
			@apply w-full
		.caption
			@apply p-0 mt-5
		.title, .global-desc, .facilities
			@apply mb-4

.room-list
	.room-item
		@apply bg-neutral-50 py-10 xl:py-15
		&:nth-child(even)
			@apply bg-white
			.caption
				@apply bg-neutral-50
			.facilities
				li
					&:nth-child(n+7)
						@apply hidden
			.button
				@apply flex
		@screen lg
			&:nth-child(even)
				.container
					@apply flex-row-reverse


.room-section
	.block-title
		@apply pb-8 bg-neutral-50 pt-15 xl:pt-20 xl:pb-5
		.global-desc
			@apply font-secondary text-neutral-900
	.room-item
		@apply last:mb-15 xl:last:mb-20
	.global-desc
		@apply site-regular-20
