.experience-item
	@apply relative
	.image
		@apply aspect-[16/10] img-cover
	.caption
		@apply mt-5
	.title
		@apply body-1
		@apply text-neutral-900 font-bold line-clamp-2 hover:text-secondary-500
	.brief
		@apply body-4
		@apply mt-3 text-neutral-400 line-clamp-4
	&:hover
		.image
			img
				@apply scale-110
	&.is-big
		.image
			&::before
				content: ''
				background: linear-gradient(180deg, rgba(112, 60, 0, 0.00) 0%, #703C00 100%)
				@apply absolute left-0 w-full bottom-0 h-1/2 z-1 pointer-events-none
		@screen md
			.caption
				@apply absolute bottom-0 left-0 w-full z-2 p-5
			.title
				@apply text-white
			.brief
				@apply text-white


.box-experience
	.box-head
		@apply flex items-center gap-5 mb-10 justify-between
	.box-title
		@apply heading-5
		@apply font-light text-secondary-600 uppercase
	.swiper-relative
		@apply pb-10 lg:pb-0
	& + .box-experience
		@apply mt-10 xl:mt-15
