// alert
.alert
	@apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm
	& button
		@apply hidden
	&.alert-danger
		color: #721c24
		background-color: #f8d7da
		border-color: #f5c6cb
	&.alert-success
		color: #155724
		background-color: #d4edda
		border-color: #c3e6cb

.backdrop
	@apply fixed z-500 w-full h-full top-0 left-0 bg-black/75 pointer-events-none opacity-0 transition-all
	&.open
		@apply opacity-100 pointer-events-auto

.nav-fixed
	@apply fixed z-50 bottom-25 md:bottom-5 right-3 scale-75 xl:bottom-10 xl:right-10 xl:scale-100
	ul
		@apply flex flex-col justify-center items-center gap-4
		> li
			> a
				@apply relative z-1 w-15 h-15 rounded-full flex-center bg-yellow text-2xl text-secondary-600 transition hover:bg-main
			> .nav-popup
				@apply relative z-1
				&::before
					content: ''
					@apply absolute-center w-full h-full -z-1 bg-primary-400 opacity-25 rounded-full animate-circle-zoom
	.back-to-top
		@apply opacity-0 pointer-events-none relative w-15 h-15
		&.active
			@apply opacity-100 pointer-events-auto
		.next-section
			@apply bg-white static block rotate-180
			span
				@apply after:bg-neutral-900 before:bg-neutral-900
			@media(max-width: 575.89px)
				.arrow
					&::before, &:after
						@apply animate-none
	.ActionMenu
		left: auto !important
		right: 0 !important

.social-list
	@apply flex items-center gap-3
	a
		@apply flex-center w-12 h-12 rounded-full bg-secondary-600 text-neutral-white text-2xl hover:bg-primary-600

.global-desc
	@apply prose max-w-none
	@apply body-4
	@apply text-neutral-500
	h3
		@apply title-gradient site-semi-24
		@apply font-bold bg-title font-secondary
	&.center
		@apply max-w-[calc(864/1920*100rem)] mx-auto

.full-content
	@apply prose max-w-none
	@apply body-4
	h2, h3, h4, h5
		@apply font-secondary text-secondary-500 mt-0
	h2
		@apply site-bold-36
	h3
		@apply site-bold-24
	p
		img
			@apply m-0
	.row
		@apply mb-0
		> [class*="col-"]
			@apply mb-8
	> *
		@apply first:mt-0

.next-section
	@apply hidden w-15 h-15 ring-4 ring-white shadow bg-primary-500 rounded-full cursor-pointer relative
	.arrow
		@apply absolute-center z-1
		&::before, &::after
			content: ''
			@apply absolute w-[13px] h-[1px] transition-all bg-neutral-100
			@apply bg-white animate-next-section
		&::before
			@apply -right-px rotate-[35deg]
		&::after
			@apply -left-px -rotate-[35deg]
		&:nth-child(1)
			@apply top-[calc(50%-8px)]
			&::before, &::after
				@apply h-[1px]
		&:nth-child(2)
			&::before, &::after
				@apply h-[1.5px] animation-delay-100
		&:nth-child(3)
			@apply top-[calc(50%+8px)]
			&::before, &::after
				@apply h-[2px] animation-delay-200
