+form-text
	@apply relative w-full bg-white border border-[#e5e5e5] h-12 px-5 text-base text-secondary-600 font-normal
	@apply focus:ring-0
	@apply placeholder:text-neutral-400
	@apply disabled:bg-neutral-50 disabled:border-neutral-50 disabled:text-neutral-700
	@apply read-only:bg-neutral-50 read-only:border-neutral-50 read-only:text-neutral-700

select
	// background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%230B3F7E' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/></svg>")

textarea
	@apply h-25 py-4

input[type='file']
	@apply hidden
	~ label
		@apply relative w-max bg-neutral-50 border-none h-10 px-6 py-3 text-base text-primary-700 font-normal flex-center gap-3 cursor-pointer m-0 transition-all duration-200 ease-linear hover:bg-neutral-400

input[type='checkbox']
	@apply hidden
	~ label
		@apply relative pl-5
		&::before
			@apply font-awesome content-['\f0c8'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-900 font-normal
	&:checked
		~ label
			&::before
				@apply content-['\f14a']

input[type='radio']
	@apply hidden
	~ label
		@apply relative pl-5
		&::before
			@apply font-awesome content-['\f111'] absolute top-1/2 -translate-y-1/2 left-0 text-base text-neutral-900 font-normal
	&:checked
		~ label
			&::before
				@apply content-['\f058']

.form-group
	@apply relative
	> label
		@apply relative block text-base font-normal leading-normal text-neutral-900
	[class*='fa-ex']
		@apply text-[12px] text-red-700 italic block mt-0.5 font-primary font-normal

.qradio

.qcheckbox

.qselect

.qnote

// button submit
.frm-btnwrap
	label.label, .frm-btn-reset
		@apply hidden
	.frm-btn
		@apply relative flex w-max
		&::after
			@apply font-awesome content-['\f178'] font-light text-neutral-500 absolute top-1/2 -translate-y-1/2 right-8 leading-none pointer-events-none tsn
		input[type='submit']
			@apply w-max h-12 pl-8 pr-15 py-1 text-base font-normal bg-transparent border border-neutral-500 rounded-full uppercase text-neutral-500 cursor-pointer tsn
		&:hover
			@apply after:text-primary-500
			input[type='submit']
				@apply text-primary-500 bg-secondary-600 border-secondary-600

// captcha
.frm-captcha
	@apply mt-5 flex flex-col gap-4
	.frm-captcha-input
		label
			@apply hidden
		input
			@apply bg-transparent border-[#E5E5E5] text-base font-normal text-neutral-500 px-3 py-1 min-w-[calc(160/1920*100rem)]
	.rcRefreshImage
		@apply text-0 hover:after:text-secondary-600
		&::after
			@apply font-awesome content-['\f0e2'] font-light text-3xl text-neutral-900
	.RadCaptcha
		width: 100% !important
		@apply relative flex flex-col-reverse gap-1
		> span
			@apply text-xs font-normal text-red-600
		div
			@apply flex flex-row-reverse justify-end items-center gap-4
	@screen md
		@apply flex-row
	@screen xl
		.RadCaptcha
			> span
				@apply absolute top-full left-12 mt-1


.RadUpload
	@apply max-w-full
	input[type="file"]
		@apply block
	.ruInputs
		.ruRemove
			@apply text-0 w-auto
		li:not(:last-child)
			@apply flex items-center
		li
			&:last-child
				@apply mb-0
				.ruFileWrap
					@apply block w-full h-25 rounded-2 border border-dashed border-neutral-400 bg-white p-3 flex-center
					input
						@apply w-full h-full bg-white border-none text-main text-center

.apply-frm
	@apply p-5
	.form-group
		@apply mb-8 last:mb-0
		label
			@apply text-base w-full text-main font-normal mb-1
			span
				@apply text-secondary-700 text-sm
		input:not([type="button"]), textarea
			@apply border-neutral-400
	.frm-btn
		@apply w-full flex justify-end
	@screen lg
		@apply py-8 px-10
		.form-group
			@apply flex gap-8
			label
				@apply w-[30%] mb-0 text-base
			input:not([type="button"])
				@apply h-10 text-base
			textarea
				@apply h-25 text-base
