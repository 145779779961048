@font-face
	font-family: 'Source Serif Pro'
	src: url('../fonts/SourceSerifPro-BoldIt.eot')
	src: url('../fonts/SourceSerifPro-BoldIt.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSerifPro-BoldIt.woff2') format('woff2'), url('../fonts/SourceSerifPro-BoldIt.woff') format('woff'), url('../fonts/SourceSerifPro-BoldIt.ttf') format('truetype'), url('../fonts/SourceSerifPro-BoldIt.svg#SourceSerifPro-BoldIt') format('svg')
	font-weight: bold
	font-style: italic
	font-display: swap


@font-face
	font-family: 'Source Serif Pro'
	src: url('../fonts/SourceSerifPro-Bold.eot')
	src: url('../fonts/SourceSerifPro-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSerifPro-Bold.woff2') format('woff2'), url('../fonts/SourceSerifPro-Bold.woff') format('woff'), url('../fonts/SourceSerifPro-Bold.ttf') format('truetype'), url('../fonts/SourceSerifPro-Bold.svg#SourceSerifPro-Bold') format('svg')
	font-weight: bold
	font-style: normal
	font-display: swap


@font-face
	font-family: 'Source Serif Pro'
	src: url('../fonts/SourceSerifPro-Light.eot')
	src: url('../fonts/SourceSerifPro-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSerifPro-Light.woff2') format('woff2'), url('../fonts/SourceSerifPro-Light.woff') format('woff'), url('../fonts/SourceSerifPro-Light.ttf') format('truetype'), url('../fonts/SourceSerifPro-Light.svg#SourceSerifPro-Light') format('svg')
	font-weight: 300
	font-style: normal
	font-display: swap


@font-face
	font-family: 'Source Serif Pro'
	src: url('../fonts/SourceSerifPro-It.eot')
	src: url('../fonts/SourceSerifPro-It.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSerifPro-It.woff2') format('woff2'), url('../fonts/SourceSerifPro-It.woff') format('woff'), url('../fonts/SourceSerifPro-It.ttf') format('truetype'), url('../fonts/SourceSerifPro-It.svg#SourceSerifPro-It') format('svg')
	font-weight: normal
	font-style: italic
	font-display: swap


@font-face
	font-family: 'Source Serif Pro'
	src: url('../fonts/SourceSerifPro-Black.eot')
	src: url('../fonts/SourceSerifPro-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSerifPro-Black.woff2') format('woff2'), url('../fonts/SourceSerifPro-Black.woff') format('woff'), url('../fonts/SourceSerifPro-Black.ttf') format('truetype'), url('../fonts/SourceSerifPro-Black.svg#SourceSerifPro-Black') format('svg')
	font-weight: 900
	font-style: normal
	font-display: swap


@font-face
	font-family: 'Source Serif Pro'
	src: url('../fonts/SourceSerifPro-ExtraLightIt.eot')
	src: url('../fonts/SourceSerifPro-ExtraLightIt.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSerifPro-ExtraLightIt.woff2') format('woff2'), url('../fonts/SourceSerifPro-ExtraLightIt.woff') format('woff'), url('../fonts/SourceSerifPro-ExtraLightIt.ttf') format('truetype'), url('../fonts/SourceSerifPro-ExtraLightIt.svg#SourceSerifPro-ExtraLightIt') format('svg')
	font-weight: 200
	font-style: italic
	font-display: swap


@font-face
	font-family: 'Source Serif Pro'
	src: url('../fonts/SourceSerifPro-ExtraLight.eot')
	src: url('../fonts/SourceSerifPro-ExtraLight.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSerifPro-ExtraLight.woff2') format('woff2'), url('../fonts/SourceSerifPro-ExtraLight.woff') format('woff'), url('../fonts/SourceSerifPro-ExtraLight.ttf') format('truetype'), url('../fonts/SourceSerifPro-ExtraLight.svg#SourceSerifPro-ExtraLight') format('svg')
	font-weight: 200
	font-style: normal
	font-display: swap


@font-face
	font-family: 'Source Serif Pro'
	src: url('../fonts/SourceSerifPro-BlackIt.eot')
	src: url('../fonts/SourceSerifPro-BlackIt.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSerifPro-BlackIt.woff2') format('woff2'), url('../fonts/SourceSerifPro-BlackIt.woff') format('woff'), url('../fonts/SourceSerifPro-BlackIt.ttf') format('truetype'), url('../fonts/SourceSerifPro-BlackIt.svg#SourceSerifPro-BlackIt') format('svg')
	font-weight: 900
	font-style: italic
	font-display: swap


@font-face
	font-family: 'Source Serif Pro'
	src: url('../fonts/SourceSerifPro-LightIt.eot')
	src: url('../fonts/SourceSerifPro-LightIt.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSerifPro-LightIt.woff2') format('woff2'), url('../fonts/SourceSerifPro-LightIt.woff') format('woff'), url('../fonts/SourceSerifPro-LightIt.ttf') format('truetype'), url('../fonts/SourceSerifPro-LightIt.svg#SourceSerifPro-LightIt') format('svg')
	font-weight: 300
	font-style: italic
	font-display: swap


@font-face
	font-family: 'Source Serif Pro'
	src: url('../fonts/SourceSerifPro-Semibold.eot')
	src: url('../fonts/SourceSerifPro-Semibold.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSerifPro-Semibold.woff2') format('woff2'), url('../fonts/SourceSerifPro-Semibold.woff') format('woff'), url('../fonts/SourceSerifPro-Semibold.ttf') format('truetype'), url('../fonts/SourceSerifPro-Semibold.svg#SourceSerifPro-Semibold') format('svg')
	font-weight: 600
	font-style: normal
	font-display: swap


@font-face
	font-family: 'Source Serif Pro'
	src: url('../fonts/SourceSerifPro-Regular.eot')
	src: url('../fonts/SourceSerifPro-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSerifPro-Regular.woff2') format('woff2'), url('../fonts/SourceSerifPro-Regular.woff') format('woff'), url('../fonts/SourceSerifPro-Regular.ttf') format('truetype'), url('../fonts/SourceSerifPro-Regular.svg#SourceSerifPro-Regular') format('svg')
	font-weight: normal
	font-style: normal
	font-display: swap


@font-face
	font-family: 'Source Serif Pro'
	src: url('../fonts/SourceSerifPro-SemiboldIt.eot')
	src: url('../fonts/SourceSerifPro-SemiboldIt.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSerifPro-SemiboldIt.woff2') format('woff2'), url('../fonts/SourceSerifPro-SemiboldIt.woff') format('woff'), url('../fonts/SourceSerifPro-SemiboldIt.ttf') format('truetype'), url('../fonts/SourceSerifPro-SemiboldIt.svg#SourceSerifPro-SemiboldIt') format('svg')
	font-weight: 600
	font-style: italic
	font-display: swap


