.swiper-pagination
	.swiper-pagination-bullet
		@apply opacity-100 bg-neutral-400
		&.swiper-pagination-bullet-active
			@apply bg-primary-700 opacity-100
	&.is-white
		.swiper-pagination-bullet
			@apply bg-white/50
			&.swiper-pagination-bullet-active
				@apply bg-white/100

.swiper-button
	> *
		@apply text-2xl text-secondary-500 hover:text-primary-500
		&.swiper-button-disabled
			@apply opacity-40
		&.swiper-button-lock
			@apply opacity-0 pointer-events-none
	&.is-white
		> *
			@apply text-white hover:text-neutral-200
	&.is-absolute
		> *
			@apply absolute top-1/2 -translate-y-1/2 z-1
		.button-prev
			@apply left-1
		.button-next
			@apply right-1
		&.is-top-20
			> *
				@apply top-[20%]
		&.is-top-30
			> *
				@apply top-[30%]
		&.is-top-40
			> *
				@apply top-[40%]

.swiper-relative
	.swiper-pagination
		@apply xl:hidden
	.swiper-button
		@apply hidden
		&.is-absolute
			.button-prev
				@apply -left-12
			.button-next
				@apply -right-12
		@screen xl
			@apply block
	.swiper-scrollbar
		@apply h-[2px] bg-[#D9D9D9]
		.swiper-scrollbar-drag
			@apply bg-secondary-500
