.home-banner
	@apply relative z-2
	.image
		@apply aspect-square
		img
			@apply w-full h-full object-cover
	.banner-title
		@apply site-semi-48 text-white
	.banner-brief
		@apply mt-3 heading-4 text-white font-light
	.caption
		@apply absolute bottom-0 left-0 w-full py-10
	.swiper-pagination
		@apply flex items-center static translate-x-0 translate-y-0 h-full
		.swiper-pagination-bullet
			@apply w-5 h-full bg-white/50 opacity-100 rounded-none mx-1
			&.swiper-pagination-bullet-active
				@apply bg-white
	.banner-pagination
		@apply flex absolute left-1/2 -translate-x-1/2 w-full bottom-3 z-1 h-1
	@screen md
		.image
			@apply aspect-[16/7]
		.caption
			@apply py-10
		.swiper-pagination
			.swiper-pagination-bullet
				@apply ml-0 mr-2
	@screen xl
		.caption
			@apply pt-20 pb-30 mb-6
		.banner-pagination
			@apply bottom-25
		.next-section
			@apply flex-center absolute left-0 -bottom-[calc(130/1920*100rem)]
