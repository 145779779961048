.button
	@apply flex flex-wrap gap-5

.link

.btn
	@apply flex-center h-11 px-4 gap-2 rounded-full font-bold text-secondary-600 text-sm whitespace-nowrap hover:text-primary-500
	i,em
		@apply text-xl

.btn-solid
	@apply relative bg-primary-500 hover:bg-secondary-600 hover:text-white
	i,em, span
		@apply relative z-2

.btn-lined
	@apply relative bg-transparent font-normal uppercase border border-neutral-500 text-neutral-500 hover:bg-secondary-600 hover:border-secondary-600 hover:text-white
	&.btn-white
		@apply border-white text-white
	@screen xl
		@apply px-8 gap-3 hover:gap-5

