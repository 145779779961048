.news-other-wrap
	.news-item
		@apply py-5 border-b border-b-neutral-100 first:pt-0

.news-detail-wrap
	@apply flex flex-col gap-5 relative
	.news-detail-head
		@apply flex flex-col gap-5 border-b border-b-neutral-100 pb-5
	.full-content
		> *
			@apply first:mt-0

.news-detail-section
	@screen xl
		.social-list
			@apply absolute top-0 -left-25 flex-col m-0

.toc-wrap
	@apply bg-primary-100 p-4 rounded-2 border border-secondary-400 mb-10
	.toc-title
		@apply text-sm font-medium text-secondary-600 flex items-center justify-between gap-2
	.toc-toggle
		@apply cursor-pointer
		&.active
			@apply rotate-180
	.js-toc
		@apply mt-2
	.js-toc
		@apply text-sm font-normal text-secondary-600
		ol, ul
			@apply pl-6
		.toc-list
			list-style-type: upper-roman
			.toc-list
				list-style-type: lower-alpha
				.toc-list
					list-style-type: decimal
		a
			@apply leading-normal hover-underline
	.toc-h3
		@apply pl-3
