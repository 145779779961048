.home-1-section
	@apply relative overflow-hidden z-1 bg-primary-50
	@screen xl
		&::after
			content: ''
			@apply absolute top-[40%] -translate-y-1/2 right-0 w-[calc(294/1920*100rem)] h-[calc(353/1920*100rem)] bg-[url(../img/bird-right.png)] bg-contain bg-center -z-1
		.about-count-list
			@apply grid-cols-4
			.about-count-item
				&::after
					content: ''
					@apply absolute top-1/2 -translate-y-1/2 right-0 w-px h-full bg-neutral-100 pointer-events-none
				&:nth-child(2n)
					@apply after:block
				&:nth-child(n+3)
					@apply mt-0 before:hidden
				&:last-child
					@apply after:hidden

.home-2-section
	.global-desc
		@apply site-regular-20
		@apply text-neutral-900

.home-experience-item
	@apply relative rounded-4 overflow-hidden
	.image
		@apply aspect-square img-cover
	.caption
		@apply absolute top-0 left-0 w-full p-5 z-1 bg-gradient-to-b from-black/50 to-black/0 xl:p-6 xl:min-h-[calc(200/1920*100rem)] transition-all pointer-events-none
	.category
		@apply body-4
		@apply text-white pb-2 mb-2 relative pointer-events-auto
		&::before
			content: ''
			@apply absolute bottom-0 left-0 w-10 h-px bg-white pointer-events-none
	.title
		@apply body-1
		@apply text-white font-bold pointer-events-auto
		a
			@apply hover-underline
	&:hover
		.caption
			@apply h-full

.home-3-section
	.about-4-section
		@apply mb-0

.box-home
	.box-head
		@apply flex flex-wrap justify-between gap-5

.home-4-swiper
	@apply pb-10 xl:pb-0

.home-5-item
	@apply relative overflow-hidden z-1 bg-white
	.site-title
		@apply mb-5 text-secondary-600
	.site-desc
		@apply mb-5 text-neutral-900 font-normal
	.global-desc
		@apply mb-5 text-neutral-500 font-normal
	.button
		@apply mt-5
	.image
		@apply aspect-[16/10] img-cover
	&:nth-child(odd)
		@apply bg-neutral-50
	&:nth-child(even)
		@apply bg-secondary-700
		.site-title, .site-desc, .global-desc
			@apply text-white
		.button
			a
				@apply text-white border-white
	&:first-child
		@apply bg-white
	@screen lg
		&:nth-child(2n)
			.box-home-5
				@apply flex-row-reverse
	@screen xl
		@apply py-25
		.box-home-5
			@apply min-h-[calc(600/1920*100rem)] mt-0
			> *
				@apply mt-0
		.image
			@apply absolute left-1/2 top-1/2 -translate-y-1/2 h-full max-h-[calc(600/1920*100rem)]
		&:nth-child(1)
			&::after
				content: ''
				@apply absolute top-[40%] -translate-y-1/2 left-7 w-[calc(287/1920*100rem)] h-[calc(370/1920*100rem)] bg-[url(../img/bird-left.png)] bg-contain bg-center -z-1
		&:nth-child(2n)
			.image
				@apply left-auto right-1/2
			&::after
				content: ''
				@apply absolute -bottom-[calc(155/1920*100rem)] -right-[calc(76/1920*100rem)] w-[calc(396/1920*100rem)] h-[calc(602/1920*100rem)] bg-[url(../img/gastronomy.png)] bg-contain bg-center -z-1
		&:nth-child(3)
			&::after
				content: ''
				@apply absolute bottom-0 left-0 w-[calc(236/1920*100rem)] h-[calc(399/1920*100rem)] bg-[url(../img/pine.png)] bg-contain bg-center -z-1

.home-6-item
	@apply relative
	.image
		@apply relative aspect-[16/12.5] img-cover w-full h-full
		&::before
			content: ''
			@apply bg-black/50 absolute-center w-full h-full z-1 pointer-events-none tsn
	.caption
		@apply relative p-5 flex flex-col justify-between gap-5 bg-white
	.title
		@apply site-bold-24
		@apply text-neutral-900 font-bold mb-5 hover:text-secondary-600
	.brief
		@apply body-4
		@apply text-neutral-500 font-normal
	.view-all
		@apply text-secondary-600 hover:text-primary-600
	@screen md
		&:first-child
			.caption
				@apply absolute bottom-0 left-0 px-15 py-6 z-2 gap-4 bg-transparent
			.title, .brief, .view-all
				@apply text-white
			.button
				@apply mt-6
			.view-all
				@apply inline-block
		&:nth-child(n+2)
			@apply flex
			> *
				@apply w-1/2
			.caption
				@apply p-10
				&::before
					content: ''
					@apply absolute top-1/2 -translate-y-1/2 right-full rotate-180 z-1 border-solid border-l-[20px] border-y-[30px] border-l-white border-y-transparent w-0 h-0 pointer-events-none
		&:nth-child(2n)
			@apply flex-row-reverse
			.caption
				@apply before:rotate-0 before:left-full
	@screen xl
		.image
			@apply hover:before:opacity-0
		&:nth-child(n+2)
			.caption
				@apply p-15

.home-6-wrap
	@apply px-5 md:px-5 lg:px-10 xl:px-0
	@screen xl
		@apply grid grid-cols-2
		.home-6-item
			&:nth-child(1)
				@apply row-span-2

.home-6-section
	@apply pb-0

.home-7-section
	.news-nav
		@apply mt-5
		.nav-list
			@apply justify-start mb-0
	.news-list
		@apply mt-10 xl:mt-15

.home-8-section
	@apply relative md:pb-0 lg:py-0
	.site-desc
		@apply text-neutral-900
	.global-desc
		@apply text-neutral-500
	@screen lg
		.home-8-flex
			@apply flex
			> *
				@apply w-1/2
		.home-8-content
			@apply py-20
		.home-8-video
			@apply relative z-2
	@screen xl
		.home-8-flex
			@apply justify-end
		.home-8-content
			@apply absolute top-1/2 -translate-y-1/2 left-0 w-full z-1
		.global-desc
			@apply max-w-[calc(575/1920*100rem)]

.home-review-item
	@apply flex flex-col gap-5
	.image
		@apply w-[calc(300/1920*100rem)] mx-auto
	.avatar
		@apply aspect-square rounded-full img-cover overflow-hidden
	.caption
		@apply flex-1
	.icon
		@apply text-6xl text-secondary-600 mb-1 leading-none
	.title, .name
		@apply site-bold-24
		@apply text-neutral-900 font-bold mb-5
	.brief
		@apply body-4
		@apply text-neutral-500 font-normal
	.author
		@apply mt-8 pt-4 relative xl:mt-11
		&::before
			content: ''
			@apply absolute top-0 left-0 w-20 h-px bg-secondary-600
	.name
		@apply text-secondary-600 mb-2
	.address
		@apply body-2
		@apply text-neutral-500 font-normal
	@screen lg
		@apply flex-row gap-8

.home-9-swiper
	@apply pb-10 xl:pb-0

.home-9-section
	@screen xl
		@apply py-25

.home-10-section
	.maps-section
		@apply pb-0
