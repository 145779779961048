.footer-top
	@apply py-10

.footer-title
	@apply text-base font-bold text-neutral-900 leading-1.4 mb-2 xl:text-sm

.footer-content
	@apply text-sm font-normal text-neutral-900 leading-1.33 break-words xl:text-xs
	address
		@apply block not-italic
	a
		@apply text-secondary-400 font-bold hover-underline hover:text-primary-600
	* + h4
		@apply mt-4
	& + *
		@apply mt-4

.footer-bot
	@apply bg-secondary-600 py-6
	.footer-wrap
		@apply flex flex-col gap-4 lg:flex-row lg:justify-between
	.footer-left
		@apply text-sm text-neutral-white leading-1.33 xl:text-xs
	.footer-right
		ul
			@apply text-sm text-neutral-white leading-1.33 flex flex-col gap-4 md:flex-row md:flex-wrap xl:text-xs
			li
				@apply lg:pr-4 lg:border-r lg:border-r-white lg:last:pr-0 lg:last:border-none
			a
				@apply hover-underline

footer
	@apply bg-neutral-50
