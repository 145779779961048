.promotion-detail-section
	.box-head
		@apply flex items-center gap-5 flex-wrap justify-between mb-10
	.promotion-info
		@apply mb-10
		table
			@apply w-full
		td
			@apply border-y-2
			@apply py-3 border-y border-y-neutral-100 pr-5 align-top
			&:first-child
				@apply font-bold w-1/3 md:w-[calc(304/1312*100%)]
	.full-content
		@apply body-2
		@apply text-neutral-900

.promotion-other-wrap
	@apply mt-10 xl:mt-15
	.promotion-other-swiper
		@apply pb-10 xl:pb-0
