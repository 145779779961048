.pagination
	@apply flex-center flex-wrap gap-3 mt-8
	span, a
		@apply flex-center text-base text-primary-500 text-center w-10 h-10 rounded-2 bg-secondary-600 tsn hover:bg-primary-500 hover:text-secondary-600
	li
		&.active
			span, a
				@apply bg-primary-500 text-secondary-600
	@screen xl
		@apply gap-5 mt-10
		span, a
			@apply w-12 h-12 text-xl
