.news-date
	@apply text-xs font-normal text-neutral-300 leading-1.33

.news-item
	@apply relative
	.image
		@apply aspect-[16/10.5] img-cover
	.caption
		@apply p-5 flex flex-col gap-3
	.date-cate
		@apply flex flex-wrap items-center gap-5
		> *
			@apply relative last:after:hidden
			&::after
				content: ''
				@apply absolute top-1/2 -translate-y-1/2 -right-3 block w-[2px] h-[2px] bg-neutral-400
	.cate
		@apply text-xs font-bold text-neutral-300 leading-1.33
	.title
		@apply text-base font-bold text-neutral-900 line-clamp-5 hover:text-secondary-600
	.brief
		@apply body-2
		@apply text-neutral-900 line-clamp-3
	.button
		a
			@apply text-xl text-secondary-600
	&:hover
		.image
			img
				@apply scale-110
	&.is-big
		@screen md
			.caption
				@apply absolute left-0 w-full bottom-0 bg-gradient-to-b from-black/0 to-black/50 xl:p-6
			.title
				text-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.10)
				@apply line-clamp-2 text-white text-xl
				a
					@apply hover-underline
			.news-date, .cate
				@apply text-white
			.button
				a
					@apply text-white
	&.is-other
		@apply flex gap-5
		.image
			@apply w-[192px]
		.caption
			@apply flex-1 p-0
		.title
			@apply text-base font-normal line-clamp-3
	&.is-promotion
		.image
			@apply aspect-[16/8.5]
		.caption
			@apply px-0


.nav-list
	@apply flex gap-5 mb-10 xl:gap-10 xl:justify-center
	li
		@apply w-max
		a
			@apply body-2
			@apply whitespace-nowrap text-neutral-400 hover:text-secondary-600 p-2 inline-block hover-underline
		&.active
			a
				@apply text-secondary-600
