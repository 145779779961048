.contact-info
	@apply text-neutral-900 font-normal text-sm
	ul
		@apply flex flex-col gap-3
	li
		@apply flex gap-3
		i, em
			@apply w-5 text-secondary-500 text-lg
		p
			@apply flex-1

.contact-maps
	.iframe-scale
		@apply pt-[calc(310/528*100%)]

.contact-section
	@apply overflow-hidden
	.wrap-form
		@apply relative bg-neutral-100/50 p-12 z-1
	.form-note
		@apply body-4
		@apply mb-5 text-neutral-900
	.row
		@apply -mx-3
		> *
			@apply px-3
	@screen xl
		.wrap-form
			@apply -mt-20 pt-25 pb-15 bg-transparent
			&::before
				content: ''
				@apply bg-neutral-100/50 absolute top-0 left-0 w-screen h-full pointer-events-none -z-1
		textarea
			@apply h-[calc(200/1920*100rem)]
		.frm-btnwrap
			@apply absolute bottom-15 right-12
