.room-equipment-item
	.image
		@apply aspect-square rounded-4 overflow-hidden
	.caption
		@apply mt-5
	.title
		@apply site-bold-24
		@apply text-neutral-900
	.brief
		@apply body-4
		@apply mt-2 text-neutral-900

.room-service-item
	.image
		@apply aspect-[16/9]
	.title
		@apply body-2
		@apply mt-5 text-center text-neutral-900 font-normal

.room-detail-section
	.room-item
		@apply mt-9
		.button
			@apply flex mt-4
		@screen lg
			.caption
				@apply py-0
			.title
				@apply mb-4
	.room-equipment-wrap
		@apply mt-15
	.room-service-wrap
		@apply mt-15

.news-book-now
	.book-now-title
		@apply site-bold-24
		@apply text-center text-white mb-5
	.global-desc
		@apply text-center text-white
	.button
		@apply mt-10
