header
	@apply h-[90px] relative z-100
	.header-fixed
		@apply fixed bg-secondary-600 w-full top-0 left-0 transition-all
	.header-wrap
		@apply flex items-center justify-between gap-3 xl:gap-5
	.logo
		@apply w-25 flex-center py-2
		a
			@apply flex items-center
		img
			@apply max-w-full h-[74px] object-contain transition-all
	.header-right
		@apply flex-1 flex items-center justify-end
	.navbar-nav
		@apply hidden
	.main-menu
		@apply flex items-center gap-6 2xl:gap-8
		.icon-toggle
			@apply hidden
		> li
			@apply h-[90px] flex-center tsn
			> a
				@apply inline-block py-3 text-sm font-bold text-white leading-1.4 hover-underline
			&.active
				> a
					@apply text-primary-500 hover-underline-active
			&.has-children
				@apply relative
				.sub-menu
					@apply absolute top-full left-0 w-max bg-primary-500 rounded-2 py-5 px-6 flex flex-col gap-4 tsn opacity-0 pointer-events-none -translate-y-2
					a
						@apply inline-block py-1 text-sm text-secondary-600 font-normal uppercase leading-1.4 hover-underline
					li
						&.active
							a
								@apply hover-underline-active
				&:hover
					> a
						@apply hover-underline-active
					.sub-menu
						@apply opacity-100 pointer-events-auto translate-y-0
	.button-group
		@apply flex items-center ml-4 relative
	.button-language
		@apply px-3 cursor-pointer
		.dropdown
			.dropdown-toggle
				@apply h-10 flex-center gap-1 text-primary-500
			.current-language
				@apply flex-center gap-1 text-xs font-normal text-secondary-300
			&.active
				.current-language
					i
						@apply rotate-180
	.button-search
		@apply hidden w-9 h-10 text-base text-primary-500 cursor-pointer tsn hover:text-primary-600
	.button-contact
		@apply hidden
	&.active
		.logo
			img
				@apply h-[54px]
		.header-fixed
			@apply shadow
		.main-menu
			> li
				@apply h-[70px]
	@screen md
		.button-contact
			@apply flex
	@screen xl
		.navbar-nav
			@apply flex-1 flex items-center justify-end
		.button-group
			&::before
				content: ''
				@apply w-px h-5 bg-secondary-400 absolute top-1/2 -translate-y-1/2 left-0
		.button-search
			@apply flex-center

.mobile-wrap
	@apply flex flex-col fixed top-0 w-screen h-screen bg-primary-600 z-999 pt-10 pl-4 pb-4 max-w-xs
	@apply -left-full opacity-0 transition-all duration-500 ease-in-out pointer-events-none
	.navbar-nav-list
		@apply flex-1 pr-4 max-h-full h-full overflow-y-auto
	.main-menu
		.icon-toggle
			@apply absolute top-0 right-0 z-1 w-10 h-10 flex-center text-secondary-700 text-base tsn
			&.active
				@apply -rotate-180
		> li
			@apply mt-3
			> a
				@apply inline-block py-2 text-lg font-medium text-secondary-700 hover-underline
			&.active
				> a
					@apply hover-underline-active
			&.has-children
				@apply relative
				.sub-menu
					@apply pl-5 hidden
					a
						@apply inline-block py-1 text-sm text-secondary-700 font-normal uppercase leading-1.4
					li
						@apply mt-1
						&.active
							> a
								@apply hover-underline-active
	.searchbox
		@apply ml-0 mb-5 mr-4 w-auto max-w-full
		input
			@apply h-[40px]
		button
			@apply text-secondary-700
	.button-contact
		@apply mt-5
	&.open
		@apply left-0 opacity-100 shadow pointer-events-auto
	@screen xl
		@apply hidden

.searchbox
	@apply relative
	input
		@apply w-full h-10 border-neutral-300 border rounded-1 pl-5 pr-10 text-base text-neutral-900 placeholder:text-neutral-500 focus:outline-none focus:border-primary-700
	button
		@apply absolute top-1/2 -translate-y-1/2 right-1 z-1 w-10 h-10 flex-center text-xl text-primary-600
	@screen xl
		@apply container
		@apply px-0

.search-wrap
	@apply fixed top-1/4 left-1/2 -translate-x-1/2 w-full max-w-lg bg-neutral-900 shadow z-999 px-3 py-2 rounded-1 border border-neutral-400 transition-all duration-300 ease-in-out
	@apply opacity-0 pointer-events-none
	.searchbox
		input
			@apply bg-transparent border-none text-white
			@apply placeholder:text-neutral-400 placeholder:font-light
		button
			@apply text-white
	&.open
		@apply opacity-100 pointer-events-auto

// button toggle menu
#buttonMenu
	@apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none m-0 ml-5 p-0 bg-none
	.line
		@apply absolute block h-[2px] w-full bg-white rounded-full opacity-100 left-0 rotate-0 transition-all
		&:nth-child(1)
			@apply top-[4px]
		&:nth-child(2)
			@apply top-1/2 -translate-y-1/2
		&:nth-child(3)
			@apply bottom-[4px]
	#pulseMe
		@apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1
	.bar
		@apply bg-white rounded-1 absolute shadow-bar
		&.left
			@apply w-px animate-left-bar
		&.top
			@apply h-px animate-top-bar
		&.right
			@apply w-px animate-right-bar
		&.bottom
			@apply h-px animate-bottom-bar
	&.open, &:hover
		.line
			&:nth-child(2)
				@apply w-[15px]
		.bar
			@apply hidden
	@screen xl
		display: none !important
