.heading-1
	@apply text-5xl leading-1.33 xl:text-6xl

.heading-2
	@apply text-4xl leading-1.33 xl:text-5xl

.heading-3
	@apply text-3xl leading-1.33 xl:text-4xl

.heading-4
	@apply text-2xl leading-1.33 xl:text-3xl

.heading-5
	@apply text-xl leading-1.33 xl:text-2xl

.body-1
	@apply text-xl leading-1.4

.body-2
	@apply text-[14px] leading-normal xl:text-base

.body-4
	@apply text-[14px] leading-1.4 xl:text-sm

.body-5
	@apply text-[14px] leading-1.33 xl:text-xs

.global-title
	@apply font-primary

.site-title
	@apply font-secondary font-semibold

.site-semi-48
	@apply site-title text-5xl leading-[calc(52/48)] font-semibold xl:text-6xl

.site-bold-36
	@apply site-title text-3xl leading-1.33 font-bold xl:text-4xl

.site-semi-36
	@apply site-title text-3xl leading-1.33 font-semibold xl:text-4xl

.site-bold-24
	@apply site-title text-[20px] leading-1.33 font-bold xl:text-2xl

.site-semi-24
	@apply site-title text-[20px] leading-1.33 font-semibold xl:text-2xl

.site-regular-20
	@apply site-title text-[18px] leading-1.4 font-normal xl:text-xl

.site-regular-16
	@apply site-title text-base leading-normal font-normal

.title-gradient
	-webkit-text-fill-color: transparent
	@apply bg-clip-text

.site-desc
	@apply site-regular-20
