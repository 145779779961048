// .col
// 	-ms-flex-preferred-size: 0
// 	flex-basis: 0
// 	-ms-flex-positive: 1
// 	flex-grow: 1
// 	max-width: 100%

.col-auto
	-ms-flex: 0 0 auto
	flex: 0 0 auto
	width: auto
	max-width: 100%

@for $col from 1 through 12
	.col-#{$col}
		-ms-flex: 0 0 (100% / 12 * $col)
		flex: 0 0 (100% / 12 * $col)
		max-width: (100% / 12 * $col)

@screen sm
	.col-sm
		-ms-flex-preferred-size: 0
		flex-basis: 0
		-ms-flex-positive: 1
		flex-grow: 1
		max-width: 100%
	.col-sm-auto
		-ms-flex: 0 0 auto
		flex: 0 0 auto
		width: auto
		max-width: 100%
	@for $col from 1 through 12
		.col-sm-#{$col}
			-ms-flex: 0 0 (100% / 12 * $col)
			flex: 0 0 (100% / 12 * $col)
			max-width: (100% / 12 * $col)

@screen md
	.col-md
		-ms-flex-preferred-size: 0
		flex-basis: 0
		-ms-flex-positive: 1
		flex-grow: 1
		max-width: 100%
	.col-md-auto
		-ms-flex: 0 0 auto
		flex: 0 0 auto
		width: auto
		max-width: 100%
	@for $col from 1 through 12
		.col-md-#{$col}
			-ms-flex: 0 0 (100% / 12 * $col)
			flex: 0 0 (100% / 12 * $col)
			max-width: (100% / 12 * $col)

@screen lg
	.col-lg
		-ms-flex-preferred-size: 0
		flex-basis: 0
		-ms-flex-positive: 1
		flex-grow: 1
		max-width: 100%
	.col-lg-auto
		-ms-flex: 0 0 auto
		flex: 0 0 auto
		width: auto
		max-width: 100%
	@for $col from 1 through 12
		.col-lg-#{$col}
			-ms-flex: 0 0 (100% / 12 * $col)
			flex: 0 0 (100% / 12 * $col)
			max-width: (100% / 12 * $col)

@screen xl
	.col-xl
		-ms-flex-preferred-size: 0
		flex-basis: 0
		-ms-flex-positive: 1
		flex-grow: 1
		max-width: 100%
	.col-xl-auto
		-ms-flex: 0 0 auto
		flex: 0 0 auto
		width: auto
		max-width: 100%
	@for $col from 1 through 12
		.col-xl-#{$col}
			-ms-flex: 0 0 (100% / 12 * $col)
			flex: 0 0 (100% / 12 * $col)
			max-width: (100% / 12 * $col)

@screen 2xl
	.col-2xl
		-ms-flex-preferred-size: 0
		flex-basis: 0
		-ms-flex-positive: 1
		flex-grow: 1
		max-width: 100%
	.col-2xl-auto
		-ms-flex: 0 0 auto
		flex: 0 0 auto
		width: auto
		max-width: 100%
	@for $col from 1 through 12
		.col-2xl-#{$col}
			-ms-flex: 0 0 (100% / 12 * $col)
			flex: 0 0 (100% / 12 * $col)
			max-width: (100% / 12 * $col)
